import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import API from '../../api/API';
import shippingDataHelper from '../../helpers/shippingDataHelper';
import { Installment } from '../../types/Installment';
import { InstallmentWithoutMPInterest } from '../../types/InstallmentWithoutMPInterest';
import { MPInstallmentOption } from '../../types/MPInstallmentOption';
import { PaymentMode } from '../../types/PaymentMode';
import { UserContext } from '../User';
import { ContextProps } from './types';

export const CheckoutContext = createContext<ContextProps>({} as ContextProps);

// TODO: Delegar todo el manejo del checkout a este context, con reducer y en typescript
const CheckoutProvider = ({ children }: PropsWithChildren<unknown>) => {
    const location = useLocation();
    const [userState] = useContext(UserContext);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const [actualStep, setActualStep] = useState(!userState?.user?.role ? 0 : 1);
    const [availableInstallmentOptions, setAvailableInstallmentOptions] = useState<InstallmentWithoutMPInterest[]>([]);
    const [allowedInstallmentOptions, setAllowedInstallmentOptions] = useState<Installment[]>([]);
    const [installmentOptionsAllowedByMP, setInstallmentOptionsAllowedByMP] = useState<MPInstallmentOption[]>([]);
    const [installmentSelected, setInstallmentSelected] = useState<Installment>();
    const [selectedPaymentMode, setSelectedPaymentMode] = useState<PaymentMode>();
    // TODO: Pendiente de tipar shippingData y usarlo directamente aquí sin el shippingDataHelper
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
    const [shippingData, setShippingData] = shippingDataHelper.usePersistedData('shippingData', { ...userState?.user?.shippingData });

    const reloadAvailableInstallmentOptions = async () => {
        setAvailableInstallmentOptions([]);
        try {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            if (userState?.user?.cart?.id) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                const { data } = await API.carts.getAvailableInstallmentOptions({ id: userState.user?.cart?.id, shippingCost: shippingData.price });
                setAvailableInstallmentOptions(data);
            }
        } catch (error) {
            console.error('Error fetching installment options', error);
        }
    };

    const onInstallmentsAllowedByMPChange = (installmentOptions: MPInstallmentOption[]) => {
        handleResetInstallmentSelected();
        setInstallmentOptionsAllowedByMP(installmentOptions);
    };

    useEffect(() => {
        if (!availableInstallmentOptions.length || !installmentOptionsAllowedByMP.length) setAllowedInstallmentOptions([]);
        else {
            const isAllowedByMPForCurrentSelectedCardNumber = ({ installments }: InstallmentWithoutMPInterest) =>
                installmentOptionsAllowedByMP.some((installmentOption) => installmentOption.installments === installments);

            const appendMPInterestRateToInstallment = (installment: InstallmentWithoutMPInterest): Installment => {
                const installmentOption = installmentOptionsAllowedByMP.find(({ installments }) => installments === installment.installments);
                if (!installmentOption) throw new Error("Couldn't find MP installment option for installment");
                const MPInterest = installment.totalPriceWithGFInterest * (installmentOption.installment_rate / 100);
                return {
                    ...installment,
                    MPInterest,
                    totalPrice: installment.totalPriceWithGFInterest + MPInterest,
                    isSubsidized: installmentOption.isSubsidized,
                };
            };

            const newAllowedInstallmentOptions = availableInstallmentOptions.filter(isAllowedByMPForCurrentSelectedCardNumber);
            const newAllowedInstallmentOptionsWithMPInterestRate = newAllowedInstallmentOptions.map(appendMPInterestRateToInstallment);
            setAllowedInstallmentOptions(newAllowedInstallmentOptionsWithMPInterestRate);
        }
    }, [availableInstallmentOptions, installmentOptionsAllowedByMP, selectedPaymentMode]);

    useEffect(() => {
        if (availableInstallmentOptions.length) return;
        void reloadAvailableInstallmentOptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        void reloadAvailableInstallmentOptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps, @typescript-eslint/no-unsafe-member-access
    }, [shippingData?.price, userState?.user?.cart]);

    const resetAllStates = () => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        setActualStep(!userState?.user?.role ? 0 : 1);
        setInstallmentSelected(undefined);
        setSelectedPaymentMode(undefined);
    };

    useEffect(() => {
        if (location.pathname !== '/completeOrder' && actualStep > 1) {
            resetAllStates();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const handleResetInstallmentSelected = () => {
        setInstallmentSelected(undefined);
    };

    const contextValue = {
        actualStep,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        shippingData,
        installmentSelected,
        selectedPaymentMode,
        allowedInstallmentOptions,
        onInstallmentsAllowedByMPChange,
        setActualStep,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        setShippingData,
        reloadAvailableInstallmentOptions,
        availableInstallmentOptions,
        setInstallmentSelected,
        setSelectedPaymentMode,
        handleResetInstallmentSelected,
    };

    return <CheckoutContext.Provider value={contextValue}>{children}</CheckoutContext.Provider>;
};

export default CheckoutProvider;
