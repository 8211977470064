import { Box, Typography } from '@material-ui/core';

import colors from '../../theme/colors';

export const StyledTitle = ({ children }) => (
    <Typography variant="h6" style={{ marginBottom: '8px', color: colors.white }}>
        {children}
    </Typography>
);

export const StyledTypography = ({ children, textStrong }) => (
    <Typography variant="subtitle1" style={{ marginBottom: '4px', color: colors.white, fontWeight: textStrong ? 600 : 300 }}>
        {children}
    </Typography>
);

const TextBlock = ({ blockTitle, blockTexts, textStrong = false }) => (
    <Box my={2}>
        <StyledTitle>{blockTitle}</StyledTitle>

        {blockTexts.map((text) => (
            <StyledTypography key={text} textStrong={textStrong}>
                {text}
            </StyledTypography>
        ))}
    </Box>
);

export default TextBlock;
