import { KeyValue } from '../types/KeyValue';
import { ProductType } from '../types/ProductType';
import { genericSpecChoices, specChoicesForProductType } from './choices/choices';

export const capitalizeFirstLetter = (text: string): string => text.charAt(0).toUpperCase() + text.slice(1);

function separateText(text: string) {
    return text.replaceAll('_', ' ');
}

const dictionary: KeyValue[] = [
    { key: 'brand', value: 'Marca' },
    { key: 'case_radiator_width', value: 'Ancho del radiador en el gabinete' },
    { key: 'connectivity', value: 'Conectividad' },
    { key: 'cpu_brand', value: 'Marca CPU' },
    { key: 'fan_slots_120', value: 'Ranuras ventilador 120' },
    { key: 'fan_slots_140', value: 'Ranuras ventilador 140' },
    { key: 'fan_slots_240', value: 'Ranuras ventilador 240' },
    { key: 'fan_slots_280', value: 'Ranuras ventilador 280' },
    { key: 'fan_slots_360', value: 'Ranuras ventilador 360' },
    { key: 'interface', value: 'Interfaz' },
    { key: 'keyboard', value: 'Teclado' },
    { key: 'monitor', value: 'Monitor' },
    { key: 'mother_form_factor', value: 'Form factor del Mother' },
    { key: 'mouse', value: 'Mouse' },
    { key: 'operativeSystem', value: 'Sistema Operativo' },
    { key: 'pci_express_1x_slots', value: 'Ranuras PCI Express 1x' },
    { key: 'pci_express_2x_slots', value: 'Ranuras PCI Express 2x' },
    { key: 'pci_express_4x_slots', value: 'Ranuras PCI Express 4x' },
    { key: 'pci_express_8x_slots', value: 'Ranuras PCI Express 8x' },
    { key: 'pci_express_16x_slots', value: 'Ranuras PCI Express 16x' },
    { key: 'ports', value: 'Puertos' },
    { key: 'power', value: 'Fuente' },
    { key: 'quantity', value: 'Cantidad' },
    { key: 'radiator_support', value: 'Soporte para radiador' },
    { key: 'sockets', value: 'Sockets compatibles' },
    { key: 'size', value: 'Tamaño' },
    { key: 'video_output', value: 'Salida de video' },
    { key: 'watts', value: 'Watts' },
    { key: 'watts_supported', value: 'Watts Soportados' },
    { key: 'width', value: 'Ancho' },
];

const caseDictionary: KeyValue[] = [
    { key: 'powerSupplyIncluded', value: 'Fuente' },
    { key: 'includedPowerSupplySupportedWatts', value: 'Watts fuente' },
    { key: 'peripheralsIncluded', value: 'Periféricos' },
    { key: 'mother_form_factor', value: 'Factor Mother' },
    { key: 'size', value: 'Tamaño de fuente' },
    { key: 'powerSupplySlotLocation', value: 'Ubicación fuente' },
    { key: 'lighting', value: 'Iluminación' },
    { key: 'form_factor', value: 'Tamaño Gabinete' },
    { key: 'width', value: 'Width (mm)' },
    { key: 'watts', value: 'Watts consumidos' },
    { key: 'radiator_support', value: 'Radiator Support' },
    { key: 'frontPreinstalledFans120', value: 'Ventiladores frontales preinstalados (120mm)' },
    { key: 'frontPreinstalledFans140', value: 'Ventiladores frontales preinstalados (140mm)' },
    { key: 'frontFanSlots120', value: 'Slots disponibles para ventiladores frontales (120mm)' },
    { key: 'frontFanSlots140', value: 'Slots disponibles para ventiladores frontales (140mm)' },
    { key: 'frontFanSlotsHybrid', value: 'Slots disponibles para ventiladores frontales (híbridos: 120mm / 140mm)' },
    { key: 'topPreinstalledFans120', value: 'Ventiladores superiores preinstalados (120mm)' },
    { key: 'topPreinstalledFans140', value: 'Ventiladores superiores preinstalados (140mm)' },
    { key: 'topFanSlots120', value: 'Slots disponibles para ventiladores superiores (120mm)' },
    { key: 'topFanSlots140', value: 'Slots disponibles para ventiladores superiores (140mm)' },
    { key: 'topFanSlotsHybrid', value: 'Slots disponibles para ventiladores superiores (híbridos: 120mm / 140mm)' },
    { key: 'rearPreinstalledFans120', value: 'Ventiladores traseros preinstalados (120mm)' },
    { key: 'rearPreinstalledFans140', value: 'Ventiladores traseros preinstalados (140mm)' },
    { key: 'rearFanSlots120', value: 'Slots disponibles para ventiladores traseros (120mm)' },
    { key: 'rearFanSlots140', value: 'Slots disponibles para ventiladores traseros (140mm)' },
    { key: 'rearFanSlotsHybrid', value: 'Slots disponibles para ventiladores traseros (híbridos: 120mm / 140mm)' },
    { key: 'bottomPreinstalledFans120', value: 'Ventiladores inferiores preinstalados (120mm)' },
    { key: 'bottomPreinstalledFans140', value: 'Ventiladores inferiores preinstalados (140mm)' },
    { key: 'bottomFanSlots120', value: 'Slots disponibles para ventiladores inferiores (120mm)' },
    { key: 'bottomFanSlots140', value: 'Slots disponibles para ventiladores inferiores (140mm)' },
    { key: 'bottomFanSlotsHybrid', value: 'Slots disponibles para ventiladores inferiores (híbridos: 120mm / 140mm)' },
    { key: 'sidePreinstalledFans120', value: 'Ventiladores laterales preinstalados (120mm)' },
    { key: 'sidePreinstalledFans140', value: 'Ventiladores laterales preinstalados (140mm)' },
    { key: 'sideFanSlots120', value: 'Slots disponibles para ventiladores laterales (120mm)' },
    { key: 'sideFanSlots140', value: 'Slots disponibles para ventiladores laterales (140mm)' },
    { key: 'sideFanSlotsHybrid', value: 'Slots disponibles para ventiladores laterales (híbridos: 120mm / 140mm)' },
];

const caseFanDictionary: KeyValue[] = [
    { key: 'watts', value: 'Watts' },
    { key: 'width', value: 'Tamaño' },
    { key: 'connection', value: 'Conexión' },
    { key: 'lighting', value: 'Iluminación' },
];

const chairDictionary: KeyValue[] = [
    { key: 'material', value: 'Material' },
    { key: 'maximumSupportedWeight', value: 'Peso Máximo Soportado' },
];

const cpuDictionary: KeyValue[] = [
    { key: 'igpu', value: 'Gráficos integrados' },
    { key: 'brand', value: 'Marca' },
    { key: 'socket', value: 'Socket' },
    { key: 'has_cooler', value: 'Cooler integrado' },
    { key: 'watts', value: 'Watts' },
    { key: 'model', value: 'Modelo' },
    { key: 'tdpInWatts', value: 'TDP medido en watts' },
];

const gpuDictionary: KeyValue[] = [
    { key: 'width', value: 'Tamaño (mm)' },
    { key: 'watts', value: 'Watts' },
    { key: 'chip', value: 'Chip' },
    { key: 'model', value: 'Modelo' },
    { key: 'vramTech', value: 'Tecnología VRAM' },
    { key: 'vramCapacity', value: 'Capacidad VRAM' },
    { key: 'hdmi', value: 'HDMI' },
    { key: 'displayPorts', value: 'Display ports' },
];

const cpuCoolerDictionary: KeyValue[] = [
    { key: 'cooler_type', value: 'Tipo' },
    { key: 'sockets', value: 'Sockets' },
    { key: 'case_radiator_width', value: 'Tamaño Radiador' },
    { key: 'fanSize', value: 'Tamaño Ventilador' },
    { key: 'lighting', value: 'Iluminación' },
    { key: 'screen', value: 'Pantalla' },
    { key: 'tdpInWatts', value: 'TDP medido en watts' },
];

const headsetDictionary: KeyValue[] = [
    { key: 'connectivity', value: 'Conexión' },
    { key: 'connectionType', value: 'Tipo de Conexión' },
    { key: 'withMicrophone', value: 'Con Micrófono' },
    { key: 'lighting', value: 'Iluminación' },
    { key: 'sound', value: 'Sonido' },
    { key: 'noiseCancelling', value: 'Cancelación de Ruido' },
    { key: 'format', value: 'Formato' },
];

const keyboardDictionary: KeyValue[] = [
    { key: 'type', value: 'Tipo de teclado' },
    { key: 'keyboardSwitch', value: 'Switch' },
    { key: 'backlight', value: 'Retroiluminación' },
    { key: 'connectivity', value: 'Conectividad' },
    { key: 'programmableKeys', value: 'Teclas Programables' },
];

const monitorDictionary: KeyValue[] = [
    { key: 'amdFreeSync', value: 'AMD FreeSync' },
    { key: 'connections', value: 'Conectividad' },
    { key: 'curvedScreen', value: 'Pantalla Curva' },
    { key: 'inches', value: 'Tamaño de pantalla (pulgadas)' },
    { key: 'nvidiaGSync', value: 'Nvidia G-Sync' },
    { key: 'panelType', value: 'Tipo de Panel' },
    { key: 'refresh_rate', value: 'Frecuencia' },
    { key: 'responseTime', value: 'Tiempo de Respuesta' },
    { key: 'resolution', value: 'Resolución' },
    { key: 'vesa', value: 'Vesa' },
];

const motherDictionary: KeyValue[] = [
    { key: 'cpu_socket', value: 'Socket' },
    { key: 'form_factor', value: 'Form Factor' },
    { key: 'integratedWifi', value: 'Wifi integrada' },
    { key: 'm2_slots', value: 'Slots M.2' },
    { key: 'pci_express_16x_slots', value: 'Slots PCIe 16x' },
    { key: 'ram_slots', value: 'Slots RAM' },
    { key: 'ram_stick_type', value: 'Tipo de RAM' },
    { key: 'sata3_slots', value: 'Puertos SATA' },
];

const mouseDictionary: KeyValue[] = [
    { key: 'adjustableDPI', value: 'DPI Ajustable' },
    { key: 'maxDPI', value: 'DPI Máximo' },
    { key: 'lighting', value: 'Iluminación' },
    { key: 'connectivity', value: 'Conectividad' },
    { key: 'programmableButtons', value: 'Botones Programables' },
];

const powerDictionary: KeyValue[] = [
    { key: 'watts_supported', value: 'Watts nominal' },
    { key: 'cabling', value: 'Cableado' },
    { key: 'certification80Plus', value: 'Certificación 80 Plus' },
    { key: 'format', value: 'Formato' },
    { key: 'lighting', value: 'Iluminación' },
];

const ramDictionary: KeyValue[] = [
    { key: 'capacity', value: 'Capacidad' },
    { key: 'cl', value: 'Latencia' },
    { key: 'clock_frequency', value: 'Frecuencia' },
    { key: 'clockFrequencyUnit', value: 'Frecuencia Mhz/MTs' },
    { key: 'stick_type', value: 'Tecnología' },
    { key: 'type', value: 'Tipo' },
];

const storageDictionary: KeyValue[] = [
    { key: 'capacity', value: 'Capacidad (GB)' },
    { key: 'connection', value: 'Conexión' },
    { key: 'notebookCompatible', value: 'Compatible con Notebooks' },
    { key: 'protocol', value: 'Protocolo' },
    { key: 'technology', value: 'Tecnología' },
    { key: 'type', value: 'Tipo' },
    { key: 'watts_supported', value: 'Watts' },
];

const buildDictionary = [
    { key: 'cpu', value: 'CPU' },
    { key: 'cpuCooler', value: 'CPU Cooler' },
    { key: 'ram', value: 'RAM' },
    { key: 'Gpu', value: 'GPU' },
    { key: 'storage', value: 'Almacenamiento' },
    { key: 'case', value: 'Gabinete' },
    { key: 'fans', value: 'Fan' },
];

const dictionariesForProductType: Record<ProductType, KeyValue[]> = {
    build: buildDictionary,
    case: caseDictionary,
    case_fan: caseFanDictionary,
    chair: chairDictionary,
    cpu: cpuDictionary,
    cpu_cooler: cpuCoolerDictionary,
    gpu: gpuDictionary,
    headset: headsetDictionary,
    keyboard: keyboardDictionary,
    mouse: mouseDictionary,
    monitor: monitorDictionary,
    mother: motherDictionary,
    power: powerDictionary,
    ram: ramDictionary,
    storage: storageDictionary,
    microphone: [],
    accessories: [],
    thermal_paste: [],
    speakers: [],
    furniture: [],
    operative_system: [],
    other: [],
    notebook: [],
};

export const getSpecTitle = (spec: string, productType?: ProductType): string => {
    if (!productType) return separateText(capitalizeFirstLetter(spec));
    const textNew =
        dictionariesForProductType[productType].find((item) => item.key === spec)?.value ?? dictionary.find((item) => item.key === spec)?.value ?? separateText(capitalizeFirstLetter(spec));

    return textNew;
};

export const cleanDescription = (description?: string) => {
    if (typeof description === 'string') {
        return description.replace('<p>', '').replace('</p>', '');
    }
    return description;
};

//FIXME: Ignoro todos los errores de esta función porque tienen que pasar a usarse la metadata del backend y eliminar las que están acá
export const getSpecValueToShow = (specKey: string, specValue: string, productType: ProductType): string => {
    //eslint-disable-next-line
    const value =
        specChoicesForProductType[productType]?.[specKey]?.find((item: KeyValue) => item.key === specValue) ??
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        genericSpecChoices[specKey as keyof typeof genericSpecChoices]?.find((item: KeyValue) => item.key === specValue);
    return cleanDescription(value?.value) ?? specValue;
};
