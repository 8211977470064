import { makeStyles } from '@material-ui/core';

import colors from '../../theme/colors';

export const usePayerCostTableStyles = makeStyles((theme) => ({
    heading: {
        fontSize: 15,
        fontWeight: 400,
        color: colors.blackGrey,
        [theme.breakpoints.up('md')]: {
            fontWeight: 500,
        },
    },
    accordion: {
        marginTop: '1rem',
        backgroundColor: colors.grey,
        maxWidth: '418px',
        boxShadow: 'none',
        '&:before': {
            opacity: 0,
        },
    },
    accordionSummary: {
        padding: '0px 21px',
    },
    accordionDetails: {
        padding: '0px 8px 8px',
    },
    tableContainer: {
        padding: '0 10px 15px',
        [theme.breakpoints.up('md')]: {
            padding: '0 25px 20px',
        },
    },
    table: {
        width: '100%',
    },
    tableHead: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'table-header-group',
        },
    },
    tableHeader: {
        padding: '0 16px 8px',
        fontSize: 14,
        fontWeight: 400,
    },
    tableCell: {
        fontSize: 12,
        fontWeight: 300,
        textAlign: 'left',
        [theme.breakpoints.up('md')]: {
            fontSize: 13,
            textAlign: 'center',
        },
    },
    tableBoxCell: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: 5,
        [theme.breakpoints.up('md')]: {
            justifyContent: 'center',
        },
    },
    showOnDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'table-cell',
        },
    },
    disclaimerText: {
        marginTop: '1rem',
        fontSize: 8,
        fontWeight: 300,
        color: colors.blackGrey,
        lineHeight: '9.6px',
    },
    alignRightOnMobile: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'right',
        },
    },
}));
