import { createTheme } from '@material-ui/core';

import colors from './colors';
import FrutigerBlack from './fonts/FrutigerLTStd-Black.otf';
import FrutigerBold from './fonts/FrutigerLTStd-Bold.otf';
import FrutigerLight from './fonts/FrutigerLTStd-Light.otf';
import FrutigerLightItalic from './fonts/FrutigerLTStd-LightItalic.otf';
import FrutigerRegular from './fonts/FrutigerLTStd-Regular.otf';

const frutigerRegular = {
    fontFamily: 'Frutiger',
    fontStyle: 'normal',
    fontDisplay: 'fallback',
    fontWeight: 'normal',
    src: `
        local('Frutiger LT Std'),
        local('FrutigerLTStd-Regular'),
        url(${FrutigerRegular}) format('opentype')
    `,
    unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const frutigerBlack = {
    fontFamily: 'Frutiger',
    fontStyle: 'normal',
    fontDisplay: 'fallback',
    fontWeight: '900',
    src: `
        local('Frutiger LT Std'),
        local('FrutigerLTStd-Black'),
        url(${FrutigerBlack}) format('opentype')
    `,
    unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const frutigerBold = {
    fontFamily: 'Frutiger',
    fontStyle: 'normal',
    fontDisplay: 'fallback',
    fontWeight: '700',
    src: `
        local('Frutiger LT Std'),
        local('FrutigerLTStd-Bold'),
        url(${FrutigerBold}) format('opentype')
    `,
    unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const frutigerLight = {
    fontFamily: 'Frutiger',
    fontStyle: 'normal',
    fontDisplay: 'fallback',
    fontWeight: '300',
    src: `
        local('Frutiger LT Std'),
        local('FrutigerLTStd-Light'),
        url(${FrutigerLight}) format('opentype')
    `,
    unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const frutigerLightItalic = {
    fontFamily: 'Frutiger',
    fontStyle: 'italic',
    fontDisplay: 'fallback',
    fontWeight: '300',
    src: `
        local('Frutiger LT Std'),
        local('FrutigerLTStd-LightItalic'),
        url(${FrutigerLightItalic}) format('opentype')
    `,
    unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const baseTheme = createTheme({
    palette: {
        text: {
            primary: colors.black,
        },
        primary: {
            main: colors.yellow,
        },
        secondary: {
            main: colors.black,
        },
        action: {
            disabled: colors.white,
            disabledBackground: colors.grey,
        },
        info: {
            main: colors.blackGrey,
        },
    },

    typography: {
        fontFamily: '"Frutiger", sans-serif',
        h1: {
            fontWeight: 700,
            fontSize: '32px',
            lineHeight: '50px',
        },
        h2: {
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '38px',
        },
        h3: {
            fontWeight: 700,
            fontSize: '22px',
            lineHeight: '26px',
        },
        h4: {
            fontWeight: 700,
            fontSize: '20px',
            lineHeight: '24px',
        },
        h5: {
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '22px',
        },
        h6: {
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: '19.2px',
            letterSpacing: 0.5,
        },
        subtitle1: {
            fontWeight: 300,
            fontSize: '14px',
            lineHeight: '19px',
        },
        subtitle2: {
            fontWeight: 300,
            fontSize: '12px',
            lineHeight: '14px',
        },
        caption: {
            fontWeight: 300,
            fontSize: '10px',
            lineHeight: '12px',
        },
        body1: {
            fontWeight: 300,
            fontSize: '14px',
            lineHeight: '19px',
        },
    },
});

export default () =>
    createTheme({
        ...baseTheme,
        overrides: {
            MuiChip: {
                root: {
                    backgroundColor: baseTheme.palette.action.selected,
                    fontWeight: '300',
                    deleteIcon: {
                        color: '#727272',
                    },
                    height: 28,
                },
            },
            MuiCssBaseline: {
                '@global': {
                    '@font-face': [frutigerRegular, frutigerBold, frutigerBlack, frutigerLight, frutigerLightItalic],
                },
            },
            MuiRadio: {
                root: {
                    color: colors.black,
                },
            },
            MuiFormControlLabel: {
                label: {
                    fontSize: '0.75rem',
                    fontWeight: 'lighter',
                },
            },
            MuiInputLabel: {
                shrink: {
                    color: '#727272 !important',
                },
                root: {
                    '&$focused': {
                        color: '#727272 !important',
                    },
                },
            },
            MuiButton: {
                root: {
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    borderRadius: 0,
                    color: colors.black,
                    transition: 'none',
                    fontSize: '0.75rem',
                },
                contained: {
                    boxShadow: 'none',
                    minWidth: 128,
                    backgroundColor: colors.yellow,
                    border: 0,
                    padding: '8px 16px 5px 16px',
                    '&:hover': {
                        boxShadow: 'none',
                        color: colors.yellow,
                        backgroundColor: colors.black,
                    },
                    '&:disabled': {
                        color: colors.white,
                        backgroundColor: '#DBDBD5',
                    },
                },
                outlined: {
                    minWidth: 128,
                    borderWidth: 1,
                    borderColor: colors.yellow,
                    padding: '7px 15px 3px 15px',
                    '&:hover': {
                        borderColor: colors.black,
                        color: colors.black,
                        backgroundColor: 'transparent',
                    },
                    '&:disabled': {
                        color: '#DBDBD5',
                        borderColor: '#DBDBD5',
                    },
                },
                text: {
                    '&:hover': {
                        background: 'none',
                        textDecoration: 'underline',
                    },
                    '&:disabled': {
                        background: 'none',
                        color: colors.grey,
                    },
                },
                endIcon: {
                    marginLeft: 0,
                },
            },
            MuiLink: {
                root: {
                    color: colors.black,
                    fontWeight: 'bold',
                    '&:disabled': {
                        color: colors.grey,
                    },
                },
            },
            MuiList: {
                padding: {
                    paddingTop: 0,
                    paddingBottom: 0,
                },
            },
            MuiListItem: {
                button: {
                    '&:hover': {
                        backgroundColor: 'transparent',
                        textDecoration: 'underline',
                        fontWeight: 'bold',
                    },
                },
            },
            MuiMenu: {
                paper: {
                    minWidth: 137,
                    borderRadius: 0,
                    backgroundColor: '#F8F9FB',
                    border: '1px solid #DBDBD5',
                },
            },
            MuiMenuItem: {
                root: {
                    fontSize: 14,
                    fontWeight: '400!important',
                },
            },
            MuiSlider: {
                thumb: {
                    borderRadius: 0,
                },
                rail: {
                    backgroundColor: '#727272',
                    height: 2,
                    opacity: 1,
                },
                mark: {
                    backgroundColor: '#727272',
                    height: 12,
                    width: 1,
                    marginTop: -5,
                },
                markActive: {
                    backgroundColor: colors.yellow,
                },
            },
            MuiInput: {
                root: {
                    color: '#1C1C1C',
                    marginTop: 50,
                    marginBottom: 0,
                },
            },
            MuiCheckbox: {
                root: {
                    borderRadius: 0,
                    padding: 0,
                    width: 15,
                    height: 15,
                    marginRight: 4,
                },
            },
            MuiTreeItem: {
                label: {
                    paddingLeft: 0,
                    lineHeight: 1.75,
                    backgroundColor: 'transparent !important',
                },
            },
        },
    });
