import { Box, Button, CircularProgress, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ShoppingCartRoundedIcon from '@material-ui/icons/ShoppingCartRounded';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidV4 } from 'uuid';

import API from '../../../../../api/API';
import { useConfig } from '../../../../../contexts/Config/context';
import { configKeys } from '../../../../../contexts/Config/types';
import { ProductsContext } from '../../../../../contexts/Products/context';
import { UserContext } from '../../../../../contexts/User';
import userConstants from '../../../../../contexts/User/userConstants';
import emptybuild from '../../../../../empty-build.png';
import cartHelper from '../../../../../helpers/cartHelper';
import { sendAddEasyBuildToCartGAEvent } from '../../../../../helpers/gaHelper';
import handler from '../../../../../helpers/handler';
import proBuildHelper from '../../../../../helpers/proBuildHelper';
import { ProductTypes } from '../../../../../helpers/types';
import useSnackbarGD from '../../../../../hooks/useSnackbar';
import colors from '../../../../../theme/colors';
import CuotaSimpleLogo from '../../../../components/CuotaSimpleLogo';
import DescriptionProduct from '../../../../product/DescriptionProduct';
import SpecialPricePopover from '../../../../product/SpecialPricePopover';
import SpecsProduct from '../../../../product/SpecsProduct';
import ProductsInBuild from './ProductsInBuild';

const useStyles = makeStyles((theme) => ({
    actionButton: {
        width: 129,
        height: 34,
        margin: '0px 25px 16px 0px',
    },
    withBorder: {
        borderBottomColor: '#F8E837',
        borderBottomStyle: 'solid',
        borderBottomWidth: 2,
    },
    codeProduct: {
        color: '#727272',
    },
    radioGroup: {
        width: 'auto',
        height: 'auto',
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'row',
        '@media (max-width: 576px)': {
            flexDirection: 'column',
        },
    },
    radioLabel: {
        marginRight: 0,
    },
    radio: {
        paddingRight: 2,
        paddingLeft: 4,
    },
    shoppingCartIcon: {
        width: 15,
        height: 16,
        marginRight: 3,
        marginBottom: 2,
    },
    image: {
        maxHeight: 324,
        objectFit: 'contain',
        maxWidth: '100%',
    },
    imageContainer: {
        height: 324,
    },
    priceSpecial: {
        color: colors.blackGrey,
        fontWeight: 400,
        lineHeight: '38px',
        marginLeft: '8px',
    },
    detailsBox: {
        borderBottom: '1px solid #DBDDB5',
    },
    installmentsText: {
        fontWeight: 300,
        fontSize: '21px',
        color: colors.blackGrey,
        lineHeight: '27px',
    },
    cuotaSimpleContainer: {
        marginLeft: '32px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 8,
        },
    },
    buttonsContainer: {
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
}));

const Build = ({ build, isLoadingToAdd, setIsLoadingToAdd }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const {
        state: { [configKeys.MAX_INSTALLMENTS]: maxInstallments },
    } = useConfig();
    const defaultName = 'N/A';
    const [selectedSOValue, setSelectedSOValue] = useState('withoutLicense');
    const [soSelected, setSOSelected] = useState(null);
    const [loading, setLoading] = useState(false);
    const [state, dispatch] = useContext(UserContext);
    const [, setCart] = useState([]);
    const { showSnackbarMessage } = useSnackbarGD();
    const { productsDispatch } = useContext(ProductsContext);

    useEffect(() => {
        if (state.user?.cart?.id && state.user?.cart.items) {
            setCart(state.user.cart);
        } else {
            API.carts
                .post()
                .then((response) => {
                    dispatch({ type: userConstants.UPDATE_CART, cart: response.data });
                })
                .catch((error) => {
                    handler.handleError({ error, userContextDispatch: dispatch, showSnackbarMessage });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, dispatch]);

    useEffect(() => {
        if (selectedSOValue === 'withoutLicense') {
            setSOSelected(null);
        } else {
            API.products
                .getOperativeSystems()
                .then((res) => {
                    const SO = res.data[0];
                    setSOSelected(SO);
                    setLoading(false);
                })
                .catch((error) => {
                    handler.handleError({ error, userContextDispatch: dispatch, showSnackbarMessage });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSOValue, dispatch]);

    const getSpecialPriceWithSO = () => {
        if (soSelected) {
            return build.price.special.ARS + soSelected.price.special.ARS;
        }
        return build.price.special.ARS;
    };
    const getListPriceWithSO = () => {
        if (soSelected) {
            return build.price.list.ARS + soSelected.price.list.ARS;
        }
        return build.price.list.ARS;
    };

    const buildData = {
        id: build.id,
        code: build.code,
        name: build.name,
        description: build.description,
        price: {
            special: getSpecialPriceWithSO(),
            list: getListPriceWithSO(),
        },
        image: build.images && build.images[0] && build.images[0].url ? build.images[0].url : emptybuild,
        products: {
            cpu: build.specs.cpu?.description || (build.specs.cpu?.products?.length > 0 && build.specs.cpu?.products) || defaultName,
            ram: build.specs.ram?.description || (build.specs.ram?.products?.length > 0 && build.specs.ram?.products) || defaultName,
            mother: build.specs.mother?.description || (build.specs.mother?.products?.length > 0 && build.specs.mother?.products) || defaultName,
            gpu: build.specs.gpu?.description || (build.specs.gpu?.products?.length > 0 && build.specs.gpu?.products) || defaultName,
            storage: build.specs.storage?.description || (build.specs.storage?.products?.length > 0 && build.specs.storage?.products) || defaultName,
        },
        specs: {
            operativeSystem: build.specs.operativeSystem || defaultName,
            monitor: build.specs.monitor,
            mouse: build.specs.mouse,
            connectivity: build.specs.connectivity || defaultName,
            ports: build.specs.ports || defaultName,
            keyboard: build.specs.keyboard,
            cpu_brand: build.specs.cpu_brand || defaultName,
        },
    };

    const priceWithMaxInstallmentsInterests = cartHelper.getAmountWithInterest({ amount: buildData.price.special, interest: maxInstallments.interest });

    const handleChangeSelectedSOValue = async (event) => {
        setSelectedSOValue(event.target.value);
    };

    const handleAddToCart = () => {
        const newCart = state.user.cart;
        const buildSubItemIndex = uuidV4();
        const buildToAdd = {
            ...buildData,
            buildSubItemIndex,
            quantity: 1,
            type: ProductTypes.BUILD,
        };
        newCart.items.push(buildToAdd);
        if (soSelected) {
            const soToAdd = {
                ...soSelected,
                quantity: 1,
                buildId: buildSubItemIndex,
                type: ProductTypes.OPERATIVE_SYSTEM,
            };
            newCart.items.push(soToAdd);
        }
        setIsLoadingToAdd(true);
        sendAddEasyBuildToCartGAEvent({ build, soSelected });

        API.carts
            .updateCart(newCart)
            .then(async (response) => {
                cartHelper.warnIfCartHasChanged(response.data.items);
                dispatch({ type: userConstants.UPDATE_CART, cart: response.data });
                if (soSelected) showSnackbarMessage('Producto agregado al carrito.', 'success');
                else {
                    showSnackbarMessage('Producto agregado al carrito sin licencia de Windows.', 'success');
                    showSnackbarMessage('Puede agregar la licencia de Windows desde el carrito de compras.', 'success');
                }
                navigate('/checkout');
            })
            .catch((error) => {
                const customErrorMessage = 'Hubo un problema al agregar el producto. Por favor intente nuevamente en unos minutos';
                handler.handleError({
                    error,
                    userContextDispatch: dispatch,
                    showSnackbarMessage,
                    navigate,
                    redirectToHome: true,
                    customErrorMessage,
                });
            })
            .finally(() => setIsLoadingToAdd(false));
    };

    const customizeBuild = () => {
        productsDispatch({
            type: 'CREATE_BUILD_FROM_EASY_BUILDER',
            products: proBuildHelper.getProductsFromBuild(build),
        });
        navigate('/proBuilder');
    };

    return (
        <Box p={2} flexGrow={1}>
            <Grid container>
                <Grid item xs={12} lg={5}>
                    <Box className={classes.imageContainer} mb={2} pr={{ lg: 1 }}>
                        <img src={buildData.image} alt={build.name} className={classes.image} />
                    </Box>
                </Grid>
                <Grid item xs={12} lg={7}>
                    <Box pr={10} display="flex" className={classes.withBorder}>
                        <Typography variant="h6" align="left">
                            {build.name.toUpperCase()}
                        </Typography>
                    </Box>
                    <Box mt={1} display="flex">
                        <Typography variant="subtitle2" className={classes.codeProduct}>
                            {`Código del producto: ${build.code}`}
                        </Typography>
                    </Box>

                    <ProductsInBuild products={buildData.products} />

                    <Box mb={2}>
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            <RadioGroup name="license" value={selectedSOValue} onChange={handleChangeSelectedSOValue} className={classes.radioGroup}>
                                <FormControlLabel
                                    classes={{ root: classes.radioLabel }}
                                    value="withoutLicense"
                                    control={<Radio size="small" classes={{ root: classes.radio }} color="primary" />}
                                    label={
                                        <Typography style={{ marginRight: 74 }} variant="subtitle2">
                                            Sin licencia Windows 11 Home{' '}
                                        </Typography>
                                    }
                                    disabled={isLoadingToAdd}
                                />
                                <FormControlLabel
                                    classes={{ root: classes.radioLabel }}
                                    value="withLicense"
                                    control={<Radio size="small" classes={{ root: classes.radio }} color="primary" />}
                                    label={<Typography variant="subtitle2">Con licencia Windows 11 Home </Typography>}
                                    disabled={isLoadingToAdd}
                                />
                            </RadioGroup>
                        )}
                    </Box>

                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <>
                            <Box className={classes.detailsBox} pb={1}>
                                <Box display="flex" alignItems="baseline">
                                    <Typography variant="h1" align="left" style={{ lineHeight: '38px' }}>
                                        {cartHelper.formatPrice(buildData.price.special ?? 0)}
                                    </Typography>
                                    <Typography variant="subtitle1" className={classes.priceSpecial}>
                                        Precio especial
                                    </Typography>
                                    <SpecialPricePopover />
                                </Box>
                            </Box>
                            <Box style={{ marginTop: '16px', display: 'flex', flexDirection: 'row' }}>
                                <Box>
                                    <Typography variant="h2" align="left" className={classes.installmentsText}>
                                        Hasta {maxInstallments.installments} cuotas de{' '}
                                        <strong>{cartHelper.formatPrice(priceWithMaxInstallmentsInterests / maxInstallments.installments)}</strong>
                                    </Typography>
                                    <Typography
                                        variant="h4"
                                        align="left"
                                        style={{
                                            fontWeight: 400,
                                            fontSize: '14px',
                                            color: colors.blackGrey,
                                            marginTop: '4px',
                                        }}
                                    >
                                        <Typography variant="h4" style={{ fontWeight: 700, color: colors.black, display: 'inline-block' }}>
                                            {cartHelper.formatPrice(priceWithMaxInstallmentsInterests)}
                                        </Typography>{' '}
                                        Precio de lista
                                    </Typography>
                                </Box>
                                <Box className={classes.cuotaSimpleContainer}>
                                    <CuotaSimpleLogo />
                                </Box>
                            </Box>
                        </>
                    )}
                    <Box display="flex" mt={5} className={classes.buttonsContainer}>
                        {build.specs.customizable && (
                            <Button variant="outlined" onClick={customizeBuild} className={classes.actionButton} disabled={isLoadingToAdd}>
                                Personalizar
                            </Button>
                        )}
                        <Button variant="contained" onClick={handleAddToCart} className={classes.actionButton} disabled={isLoadingToAdd}>
                            <ShoppingCartRoundedIcon className={classes.shoppingCartIcon} />
                            Agregar
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <DescriptionProduct product={build} />
                    <SpecsProduct specs={buildData.specs ?? {}} />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Build;
