import { Box, Divider, Grid, Typography } from '@material-ui/core';

import { useCheckout } from '../../../contexts/Checkout/useCheckout';
import cartHelper from '../../../helpers/cartHelper';
import useStyles from './useStyles';

const ProductDetail = ({ item, isLastItem }) => {
    const classes = useStyles();
    const { getProductPriceWithGFInterest } = useCheckout();

    const price = cartHelper.formatPrice(getProductPriceWithGFInterest(item.price.special));

    return (
        <Box mb={3} key={item.id}>
            <Box mb={3}>
                <Grid container spacing={0}>
                    <Grid item xs={4}>
                        <img src={item.image} alt={item.name.toUpperCase()} className={classes.itemPhoto} />
                    </Grid>
                    <Grid item xs={8}>
                        <Typography className={classes.itemDescription} variant="subtitle2">
                            {item.name.toUpperCase()}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end" alignItems="center">
                            <Typography className={classes.itemPrice} variant="h6">
                                {price}
                            </Typography>
                            <Typography className={classes.itemQuantity} variant="subtitle1">
                                {` (x${item.quantity})`}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            {isLastItem && <Divider />}
        </Box>
    );
};

const Details = ({ showDetails }) => {
    const { productsResume } = useCheckout();

    if (!showDetails) return null;

    return (
        <Box mb={2}>
            {productsResume.map((item, i) => (
                <ProductDetail key={item.id} item={item} isLastItem={productsResume.length !== i + 1} />
            ))}
        </Box>
    );
};

export default Details;
