import { jwtDecode } from 'jwt-decode';

const initialCart = {
    id: null,
    totalPrice: {
        list: 0,
        special: 0,
    },
    items: [],
    isSubsidizedShippingPrice: false,
};

const initialUserForStorage = {
    user: {
        email: null,
        role: null,
        cart: initialCart,
    },
};

const getUser = () => {
    const userData = JSON.parse(localStorage.getItem('user'));
    if (userData && userData.cart) return { user: userData };
    return initialUserForStorage;
};

const getUserCart = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.cart) {
        return user.cart;
    }
    return initialUserForStorage.user.cart;
};

function setUser(user) {
    const cart = getUserCart();
    const userAux = user;
    if (cart) {
        userAux.cart = cart;
    }
    localStorage.setItem('user', JSON.stringify(userAux));
}

function setUserRole(role) {
    localStorage.setItem('role', role);
}

function setUserRoleByToken(token) {
    const decodedToken = jwtDecode(token);
    setUserRole(decodedToken.role);
}

function setUsername(username) {
    localStorage.setItem('username', username);
}

function deleteUserCart() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
        delete user.cart;
        localStorage.setItem('user', JSON.stringify(user));
    }
}

function setUserCart(cart) {
    const user = JSON.parse(localStorage.getItem('user'));
    let userAux = {};
    if (user) {
        userAux = {
            email: user.email,
            role: user.role,
            shippingData: user.shippingData,
            profileImageURL: user.profileImageURL,
            cart,
        };
    } else {
        userAux = { cart };
    }
    localStorage.setItem('user', JSON.stringify(userAux));
}

function setLoggedIn(loggedIn) {
    localStorage.setItem('loggedIn', loggedIn);
}

function clearStorage() {
    localStorage.clear();
}

function clearSessionStorage() {
    sessionStorage.clear();
}

function logout() {
    const cart = getUserCart();
    if (cart) {
        const userAux = { cart };
        localStorage.setItem('user', JSON.stringify(userAux));
    } else {
        clearStorage();
    }
}

function waitGoogleLogin() {
    localStorage.setItem('redirectAfterGoogleLogin', window.location.pathname);
}

function clearWaitGoogleLogin() {
    localStorage.removeItem('redirectAfterGoogleLogin');
}

const storageHelper = {
    getUser,
    setUser,
    setUserRole,
    setUserRoleByToken,
    setUsername,
    getUserCart,
    setUserCart,
    deleteUserCart,
    setLoggedIn,
    waitGoogleLogin,
    clearWaitGoogleLogin,
    clearStorage,
    clearSessionStorage,
    logout,
};

export default storageHelper;
