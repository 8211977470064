import { makeStyles } from '@material-ui/core';

export const useProductCategoriesStyles = makeStyles(() => ({
    accordion: {
        boxShadow: 'none',
        margin: '2px 0 !important',
        padding: 0,
        '&.Mui-expanded': {
            margin: '2px 0 !important',
        },
    },
    accordionDetails: {
        justifyContent: 'center',
        padding: 0,
    },
    accordionSummary: {
        padding: 0,
    },
    container: {
        backgroundColor: 'white',
        padding: '40px 80px',
        minHeight: '100dvh',
        '@media (max-width: 959px)': {
            padding: '22px 24px',
        },
        '@media (max-width: 361px)': {
            padding: '22px 16px',
        },
    },
    headerContainer: {
        marginBottom: '16px',
    },
}));

export const useProductsPageStyles = makeStyles(() => ({
    filterSpecButton: {
        justifyContent: 'flex-start',
        margin: 0,
        padding: '1px 0',
        color: '#727272',
        fontWeight: 300,
        textTransform: 'none',
        fontSize: '14px',
    },
}));

export const useProductFiltersStyles = makeStyles(() => ({
    filterCategoryTitle: {
        fontWeight: 700,
        fontSize: '16px',
        marginBottom: 6,
    },
    filterCategoryTitleAndLink: {
        cursor: 'pointer',
        fontWeight: 700,
        fontSize: '16px',
        marginBottom: 6,
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

export const useProductListStyles = makeStyles((theme) => ({
    pagination: {
        '& > *': {
            marginTop: theme.spacing(2),
        },
    },
}));
