import { Box, CircularProgress, Divider, Grid, Typography } from '@material-ui/core';

import { useCheckout } from '../../../contexts/Checkout/useCheckout';
import { useConfig } from '../../../contexts/Config/context';
import { configKeys } from '../../../contexts/Config/types';
import cartHelper from '../../../helpers/cartHelper';
import { useCouponStyles } from './useStyles';

const ShippingPriceFormatted = ({ shippingData }) => (
    <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
        {cartHelper.formatPrice(shippingData.price)}
    </Typography>
);

const ShippingCost = ({ showShippingCosts, loadingShippingPrice }) => {
    const { actualStep, shippingData } = useCheckout();
    const classes = useCouponStyles();

    const {
        state: { [configKeys.FREE_SHIPPING]: isSubsidizedShippingPrice },
    } = useConfig();

    if (!showShippingCosts || isSubsidizedShippingPrice || !shippingData?.price || actualStep < 1) return null;

    return (
        <>
            <Box mt={2} mb={2}>
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                            Envío
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Box display="flex" justifyContent="flex-end">
                            {loadingShippingPrice ? <CircularProgress size={18} color="#000" /> : <ShippingPriceFormatted shippingData={shippingData} />}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Divider className={classes.couponDivider} />
        </>
    );
};

export default ShippingCost;
