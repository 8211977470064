import { Box, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useContext, useState } from 'react';

import API from '../../../api/API';
import { useCheckout } from '../../../contexts/Checkout/useCheckout';
import { UserContext } from '../../../contexts/User';
import userConstants from '../../../contexts/User/userConstants';
import cartHelper from '../../../helpers/cartHelper';
import useSnackbarGD from '../../../hooks/useSnackbar';
import { useCouponStyles } from './useStyles';

const AppliedCouponCode = () => {
    const classes = useCouponStyles();
    const { showSnackbarMessage } = useSnackbarGD();
    const [state, dispatch] = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const { installmentSelected, onAppliedCouponsChanged } = useCheckout();

    const code = state.user?.cart?.appliedCoupon.code;

    const handleDeleteCouponCode = async () => {
        await API.carts
            .deleteCoupon({ id: state.user.cart.id })
            .then(() => {
                dispatch({ type: userConstants.UPDATE_COUPON });
                onAppliedCouponsChanged();
            })
            .catch((error) => {
                if (error.response?.status === 404) showSnackbarMessage('El código ingresado es inválido.', 'warning');
                else showSnackbarMessage('Hubo un problema al validar el código. Intenta nuevamente en unos minutos.', 'error');
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <>
            <Box className={classes.totalSubItemContainer}>
                <Typography variant="subtitle2">
                    Cupón&nbsp;
                    <strong>{code}</strong>
                </Typography>
                <Box display="flex" justifyContent="flex-end">
                    {isLoading ? (
                        <CircularProgress color="primary" />
                    ) : (
                        <Typography variant="subtitle2">
                            -&nbsp;
                            {installmentSelected ? cartHelper.formatPrice(installmentSelected.discountAmount) : cartHelper.formatPrice(state.user?.cart?.appliedCoupon.discounts.special)}
                        </Typography>
                    )}
                </Box>
            </Box>
            <Typography
                variant="subtitle2"
                onClick={handleDeleteCouponCode}
                style={{
                    fontWeight: '500',
                    cursor: 'pointer',
                    marginBottom: '10px',
                    textDecoration: 'underline',
                }}
            >
                Quitar
            </Typography>
        </>
    );
};

export default AppliedCouponCode;
