import { ProductTypes } from '../helpers/types';
import { ProductType } from '../types/ProductType';
import { ProductTypesSeoDefinition } from '../types/ProductTypesSeoDefinition';

export const helmetHomeConfig = {
    title: 'Tienda de Hardware para PC Gamer - Gamer Factory Argentina',
    description: 'Descubre la mejor tienda de hardware para PC Gamer en Gamer Factory Argentina: gabinetes, CPUs, GPUs, motherboards, fuentes, RAM, discos duros y más.',
};

export const helmetProductsCategoriesConfig = {
    title: 'Categorias - Gamer Factory Argentina',
    description: 'Descubre la mejor tienda de hardware para PC Gamer en Gamer Factory Argentina: gabinetes, CPUs, GPUs, motherboards, fuentes, RAM, discos duros y más.',
};

export const helmetProductsPageConfig = {
    title: 'Productos - Gamer Factory Argentina',
    description: 'Descubre la mejor tienda de hardware para PC Gamer en Gamer Factory Argentina: gabinetes, CPUs, GPUs, motherboards, fuentes, RAM, discos duros y más.',
};

export const helmetOffersPageConfig = {
    title: 'Ofertas - Gamer Factory Argentina',
    description: 'Descubre la mejor tienda de hardware para PC Gamer en Gamer Factory Argentina: gabinetes, CPUs, GPUs, motherboards, fuentes, RAM, discos duros y más.',
};

export const helmetEasyBuilderConfig = {
    type: 'easybuilder',
    title: 'Easy Builder - Gamer Factory Argentina',
    description: 'Descubre la mejor tienda de hardware para PC Gamer en Gamer Factory Argentina: gabinetes, CPUs, GPUs, motherboards, fuentes, RAM, discos duros y más.',
};

export const helmetProBuilderConfig = {
    type: 'probuilder',
    title: 'Pro Builder - Gamer Factory Argentina',
    description: 'Descubre la mejor tienda de hardware para PC Gamer en Gamer Factory Argentina: gabinetes, CPUs, GPUs, motherboards, fuentes, RAM, discos duros y más.',
};

export const helmetCheckoutConfig = {
    title: 'Checkout - Gamer Factory Argentina',
    description: 'Descubre la mejor tienda de hardware para PC Gamer en Gamer Factory Argentina: gabinetes, CPUs, GPUs, motherboards, fuentes, RAM, discos duros y más. ',
};

export const helmetConfigForProductTypes: Record<ProductType, ProductTypesSeoDefinition> = {
    [ProductTypes.CASE]: {
        type: ProductTypes.CASE,
        productTypeSlug: 'gabinetes-pc-gamer',
        title: 'Gabinete de Pc Gamer, Gabinetes Gamers - Gamer Factory Argentina',
        description: 'Descubre nuestra selección de gabinetes de PC gamer en Gamer Factory Argentina.',
    },
    [ProductTypes.CPU]: {
        type: ProductTypes.CPU,
        productTypeSlug: 'cpus-alto-rendimiento',
        title: 'CPUs de Alto Rendimiento - Gamer Factory Argentina',
        description: 'Encuentra las mejores CPUs de alto rendimiento para tu PC gamer en Gamer Factory Argentina.',
    },
    [ProductTypes.GPU]: {
        type: ProductTypes.GPU,
        productTypeSlug: 'gpus',
        title: 'Placa de Video para Gaming, GPU Gaming - Gamer Factory Argentina',
        description: 'Mejora tu experiencia de juego con nuestras tarjetas gráficas en Gamer Factory Argentina.',
    },
    [ProductTypes.MOTHER]: {
        type: ProductTypes.MOTHER,
        productTypeSlug: 'motherboards-gaming',
        title: 'Motherboard para Gamers, Motherboard gaming - Gamer Factory',
        description: 'Las mejores motherboards gaming para tu PC gamer están en Gamer Factory Argentina.',
    },
    [ProductTypes.POWER]: {
        type: ProductTypes.POWER,
        productTypeSlug: 'fuentes-de-alimentacion',
        title: 'Fuente de Alimentación Gaming - Gamer Factory Argentina',
        description: 'Asegura el rendimiento de tu PC con nuestras fuentes de alimentación en Gamer Factory, Argentina.',
    },
    [ProductTypes.RAM]: {
        type: ProductTypes.RAM,
        productTypeSlug: 'memorias-ram-gaming',
        title: 'Memoria RAM para PC Gamer, Memoria RAM Gaming - Gamer Factory',
        description: 'Mejora la velocidad de tu PC con nuestras memorias RAM gaming en Gamer Factory, Argentina.',
    },
    [ProductTypes.STORAGE]: {
        type: ProductTypes.STORAGE,
        productTypeSlug: 'almacenamientos',
        title: 'Disco Duro Gaming - Gamer Factory Argentina',
        description: 'Almacena tus juegos con nuestros discos duros de Gamer Factory, Argentina.',
    },
    [ProductTypes.CPU_COOLER]: {
        type: ProductTypes.CPU_COOLER,
        productTypeSlug: 'coolers-pc',
        title: 'Cooler para PC Gamer, Cooler para Gaming - Gamer Factory Argentina',
        description: 'Mantén tu PC fresca con nuestros coolers de alta eficiencia de Gamer Factory, Argentina.',
    },
    [ProductTypes.CASE_FAN]: {
        type: ProductTypes.CASE_FAN,
        productTypeSlug: 'ventiladores-pc',
        title: 'Ventilador para PC Gaming - Gamer Factory Argentina',
        description: 'Mejora la ventilación de tu PC con nuestros ventiladores de Gamer Factory, Argentina.',
    },
    [ProductTypes.THERMAL_PASTE]: {
        type: ProductTypes.THERMAL_PASTE,
        productTypeSlug: 'pastas-termicas-cpu',
        title: 'Pasta Térmica para CPU Gamer - Gamer Factory Argentina',
        description: 'Asegura una óptima disipación de calor con nuestras pastas térmicas de Gamer Factory, Argentina.',
    },
    [ProductTypes.ACCESSORIES]: {
        type: ProductTypes.ACCESSORIES,
        productTypeSlug: 'accesorios-gaming',
        title: 'Accesorios para Gaming - Gamer Factory Argentina',
        description: 'Completa tu setup gamer con nuestros accesorios de calidad en Gamer Factory, Argentina.',
    },
    [ProductTypes.HEADSET]: {
        type: ProductTypes.HEADSET,
        productTypeSlug: 'auriculares-gaming',
        title: 'Auriculares Gaming, Auriculares para Gamers - Gamer Factory',
        description: 'Sumérgete en el juego con nuestros auriculares de alta calidad de Gamer Factory, Argentina.',
    },
    [ProductTypes.MICROPHONE]: {
        type: ProductTypes.MICROPHONE,
        productTypeSlug: 'microfonos-gaming',
        title: 'Micrófonos para Gamers - Gamer Factory Argentina',
        description: 'Mejora tu comunicación con nuestros micrófonos de alta calidad en Gamer Factory, Argentina.',
    },
    [ProductTypes.FURNITURE]: {
        type: ProductTypes.FURNITURE,
        productTypeSlug: 'mobiliarios-gaming',
        title: 'Mobiliario para Gamers - Gamer Factory Argentina',
        description: 'Equipa tu espacio de juego con nuestro mobiliario especializado de Gamer Factory, Argentina.',
    },
    [ProductTypes.MONITOR]: {
        type: ProductTypes.MONITOR,
        productTypeSlug: 'monitores-gaming',
        title: 'Monitor Gamer, Monitor para Gamer - Gamer Factory Argentina',
        description: 'Descubre nuestros monitores con la mejor resolución para gaming en Gamer Factory, Argentina.',
    },
    [ProductTypes.MOUSE]: {
        type: ProductTypes.MOUSE,
        productTypeSlug: 'mouses-gaming',
        title: 'Mouse Gaming, Mouse Gamer - Gamer Factory Argentina',
        description: 'Mejora tu precisión con nuestros mouses gaming diseñados para gamers de Gamer Factory, Argentina.',
    },
    [ProductTypes.SPEAKERS]: {
        type: ProductTypes.SPEAKERS,
        productTypeSlug: 'parlantes-gaming',
        title: 'Parlantes para Gaming, Parlantes Gamers - Gamer Factory Argentina',
        description: 'Disfruta de un sonido envolvente con nuestros parlantes gaming de Gamer Factory, Argentina.',
    },
    [ProductTypes.CHAIR]: {
        type: ProductTypes.CHAIR,
        productTypeSlug: 'sillas-gaming',
        title: 'Sillas Gaming, Sillas Gamers - Gamer Factory Argentina',
        description: 'Juega cómodamente con nuestras sillas gamings diseñadas para gamers de Gamer Factory, Argentina.',
    },
    [ProductTypes.KEYBOARD]: {
        type: ProductTypes.KEYBOARD,
        productTypeSlug: 'teclados-gaming',
        title: 'Teclado para Gaming, Teclado para Gamers - Gamer Factory Argentina',
        description: 'Encuentra el teclado gaming perfecto para tus sesiones de juego en Gamer Factory, Argentina.',
    },
    [ProductTypes.BUILD]: {
        type: ProductTypes.BUILD,
        productTypeSlug: 'pcs-escritorio-gaming',
        title: 'PCs de Escritorio Gaming, PC de escritorio Gamer - Gamer Factory',
        description: 'Descubre nuestras PCs de escritorio gaming diseñadas para el gaming en Gamer Factory, Argentina.',
    },
    [ProductTypes.NOTEBOOKS]: {
        type: ProductTypes.NOTEBOOKS,
        productTypeSlug: 'notebooks-gaming',
        title: 'Notebooks para Gaming, Notebook - Gamer Factory Argentina',
        description: 'Encuentra las mejores notebooks para gaming en Gamer Factory, Argentina.',
    },
    [ProductTypes.OPERATIVE_SYSTEM]: {
        type: ProductTypes.OPERATIVE_SYSTEM,
        productTypeSlug: 'sistemas-operativos',
        title: 'Sistemas operativos para Gaming, Notebook - Gamer Factory Argentina',
        description: 'Encuentra las mejores sistemas operativos para gaming en Gamer Factory, Argentina.',
    },
    [ProductTypes.OTHER]: {
        type: ProductTypes.OTHER,
        productTypeSlug: 'otros-productos',
        title: 'Productos para Gaming, Notebook - Gamer Factory Argentina',
        description: 'Encuentra las mejores productos para gaming en Gamer Factory, Argentina.',
    },
};

export const helmetCompleteOrderConfig = {
    title: 'Completa tu orden - Gamer Factory Argentina',
    description: 'Descubre la mejor tienda de hardware para PC Gamer en Gamer Factory Argentina: gabinetes, CPUs, GPUs, motherboards, fuentes, RAM, discos duros y más. ',
};

export const helmetFAQConfig = {
    title: 'FAQ - Gamer Factory Argentina',
    description: 'Descubre la mejor tienda de hardware para PC Gamer en Gamer Factory Argentina: gabinetes, CPUs, GPUs, motherboards, fuentes, RAM, discos duros y más.',
};

export const helmetPromosConfig = {
    title: 'Promos - Gamer Factory Argentina',
    description: 'Descubre la mejor tienda de hardware para PC Gamer en Gamer Factory Argentina: gabinetes, CPUs, GPUs, motherboards, fuentes, RAM, discos duros y más.',
};

export const helmetRegretPolicyConfig = {
    title: 'Política de devolución - Gamer Factory Argentina',
    description: 'Descubre la mejor tienda de hardware para PC Gamer en Gamer Factory Argentina: gabinetes, CPUs, GPUs, motherboards, fuentes, RAM, discos duros y más.',
};

export const helmetDeleteAccountConfig = {
    title: 'Eliminar cuenta - Gamer Factory Argentina',
    description: 'Descubre la mejor tienda de hardware para PC Gamer en Gamer Factory Argentina: gabinetes, CPUs, GPUs, motherboards, fuentes, RAM, discos duros y más.',
};

export const helmetUserProfileConfig = {
    title: 'Mi cuenta - Gamer Factory Argentina',
    description: 'Descubre la mejor tienda de hardware para PC Gamer en Gamer Factory Argentina: gabinetes, CPUs, GPUs, motherboards, fuentes, RAM, discos duros y más. ',
};

export const helmetUserEditDataConfig = {
    title: 'Editar cuenta - Gamer Factory Argentina',
    description: 'Descubre la mejor tienda de hardware para PC Gamer en Gamer Factory Argentina: gabinetes, CPUs, GPUs, motherboards, fuentes, RAM, discos duros y más. ',
};
