import { useContext, useEffect } from 'react';

import API from '../../../api/API';
import { UserContext } from '../../../contexts/User';
import userConstants from '../../../contexts/User/userConstants';
import handler from '../../../helpers/handler';
import useSnackbarGD from '../../../hooks/useSnackbar';
import CartResume from '../../components/CartResume';

const Resume = ({ clearCart }) => {
    const [state, dispatch] = useContext(UserContext);
    const { showSnackbarMessage } = useSnackbarGD();

    useEffect(() => {
        if (!state.user?.cart?.id) {
            API.carts
                .post()
                .then((response) => {
                    dispatch({ type: userConstants.UPDATE_CART, cart: response.data });
                })
                .catch((error) => {
                    handler.handleError({ error, userContextDispatch: dispatch, showSnackbarMessage });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, dispatch]);

    return <CartResume clearCart={clearCart} showCartButtons />;
};

export default Resume;
