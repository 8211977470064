import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { useState } from 'react';

const useStyles = makeStyles(() => ({
    description: {
        '& h1': {
            lineHeight: 1,
        },
    },
    hiddeButton: {
        width: '100%',
        borderBottom: '1px solid',
        justifyContent: 'flex-start',
        paddingLeft: 0,
    },
    hideButtonIcon: {
        marginLeft: 'auto',
    },
}));

const DescriptionProduct = ({ product }) => {
    const classes = useStyles();
    const defaultName = 'N/A';
    const [showDescription, setShowDescription] = useState(true);

    const handleShowDescription = () => {
        setShowDescription(!showDescription);
    };

    return (
        <>
            <Box display="flex">
                <Button
                    className={classes.hiddeButton}
                    onClick={handleShowDescription}
                    classes={{ iconSizeMedium: classes.hideButtonIcon }}
                    endIcon={showDescription ? <ArrowDropUp /> : <ArrowDropDown />}
                >
                    DESCRIPCIÓN
                </Button>
            </Box>
            <Box mb={2} mt={1.25} display={showDescription ? 'flex' : 'none'} className={classes.description}>
                <Typography dangerouslySetInnerHTML={{ __html: product.description ?? defaultName }} variant="subtitle1" align="left" />
            </Box>
        </>
    );
};

export default DescriptionProduct;
