import { KeyValue } from '../../types/KeyValue';
import { CpuBrands, CpuSockets } from '../types';
import boolean from './boolean';

const brandTypes: KeyValue[] = [
    { key: CpuBrands.INTEL, value: 'Intel' },
    { key: CpuBrands.AMD, value: 'AMD' },
];

const socketTypes: KeyValue[] = [
    { key: CpuSockets.AM4, value: 'AMD AM4' },
    { key: CpuSockets.AM5, value: 'AMD AM5' },
    { key: CpuSockets.TRX4, value: 'AMD TRX4' },
    { key: CpuSockets.LGA1151, value: 'Intel 1151' },
    { key: CpuSockets.LGA1200, value: 'Intel 1200' },
    { key: CpuSockets.LGA1700, value: 'Intel 1700' },
    { key: CpuSockets.LGA1851, value: 'Intel 1851' },
    { key: CpuSockets.LGA2011, value: 'Intel 2011' },
];

const modelTypes: KeyValue[] = [
    { key: 'i3', value: 'Core I3' },
    { key: 'i5', value: 'Core I5' },
    { key: '5Ultra', value: 'Core 5 Ultra' },
    { key: 'i7', value: 'Core I7' },
    { key: '7Ultra', value: 'Core 7 Ultra' },
    { key: 'i9', value: 'Core I9' },
    { key: 'athlon', value: 'Athlon' },
    { key: 'ryzen3', value: 'Ryzen 3' },
    { key: 'ryzen5', value: 'Ryzen 5' },
    { key: 'ryzen7', value: 'Ryzen 7' },
    { key: 'ryzen9', value: 'Ryzen 9' },
];

export default {
    brand: brandTypes,
    igpu: boolean,
    has_cooler: boolean,
    model: modelTypes,
    socket: socketTypes,
};
