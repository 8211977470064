import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';

import { ProductsFiltersContext } from '../../contexts/ProductsFilters';
import { helmetProductsCategoriesConfig } from '../../data/seo';
import colors from '../../theme/colors';
import { ProductType } from '../../types/ProductType';
import ProductTypeButton from './components/ProductTypeButton';
import { offersSection, productTypeFilterSections } from './data/productTypeFilterSections';
import { useProductCategoriesStyles } from './styles';
import { getSlugForProductType } from './utils';

const ProductCategories = () => {
    const [, { setProductType, setOffersOnly }] = useContext(ProductsFiltersContext);
    const navigate = useNavigate();
    const classes = useProductCategoriesStyles();

    const handleClick = (productType: ProductType) => {
        setProductType(productType);
        navigate(`/productos/${getSlugForProductType(productType) ?? ''}`);
    };

    const handleClickOffers = () => {
        setOffersOnly(true);
        navigate('/ofertas');
    };

    return (
        <>
            <Helmet>
                <title>{helmetProductsCategoriesConfig.title}</title>
                <meta name="description" content={helmetProductsCategoriesConfig.description} />
                <meta property="og:image" content="/GD_blacklogo.png" />
                <meta property="og:title" content={helmetProductsCategoriesConfig.title} />
                <meta property="og:description" content={helmetProductsCategoriesConfig.description} />
            </Helmet>
            <Container className={classes.container} maxWidth={false}>
                <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.headerContainer}>
                    <Typography variant="h4">Categorías</Typography>
                    <Link
                        to="/productos"
                        style={{ color: colors.black }}
                        onClick={() => {
                            setProductType(undefined);
                        }}
                    >
                        Ver todos los productos
                    </Link>
                </Box>
                {productTypeFilterSections.map(({ title, items }) => (
                    <Accordion className={classes.accordion} defaultExpanded key={title}>
                        <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">{title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                            <Grid container item justifyContent="flex-start" spacing={2} xs={12}>
                                {items.map((product) => {
                                    if ('type' in product) {
                                        return (
                                            <Grid item key={product.title} xs={6} lg={2}>
                                                <ProductTypeButton
                                                    image={product.icon}
                                                    onClick={() => {
                                                        handleClick(product.type);
                                                    }}
                                                    title={product.title}
                                                />
                                            </Grid>
                                        );
                                    } else {
                                        return product.productTypes.map((productType) => (
                                            <Grid item key={productType.title} xs={6} lg={2}>
                                                <ProductTypeButton
                                                    image={productType.icon}
                                                    onClick={() => {
                                                        handleClick(productType.type);
                                                    }}
                                                    title={productType.title}
                                                />
                                            </Grid>
                                        ));
                                    }
                                })}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                ))}
                <Accordion className={classes.accordion} defaultExpanded>
                    <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">Otros</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                        <Grid container item justifyContent="flex-start" spacing={2} xs={12}>
                            <Grid item xs={12}>
                                <ProductTypeButton image={offersSection.icon} onClick={handleClickOffers} title={offersSection.title} />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Container>
        </>
    );
};

export default ProductCategories;
