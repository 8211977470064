import { KeyValue } from '../../types/KeyValue';

const types: KeyValue[] = [
    { key: 'dimm', value: 'DIMM' },
    { key: 'sodimm', value: 'SODIMM' },
];

const capacities: KeyValue[] = [
    { key: '4gb', value: '4 GB' },
    { key: '8gb', value: '8 GB' },
    { key: '16gb', value: '16 GB' },
    { key: '24gb', value: '24 GB' },
    { key: '32gb', value: '32 GB' },
    { key: '64gb', value: '64 GB' },
    { key: '98gb', value: '98 GB' },
    { key: '128gb', value: '128 GB' },
];

const stickTypes: KeyValue[] = [
    { key: 'ddr4', value: 'DDR4' },
    { key: 'ddr5', value: 'DDR5' },
];

const clockFrequencyUnits: KeyValue[] = [
    { key: 'mhz', value: 'MHz' },
    { key: 'mts', value: 'MT/s' },
];

export default {
    type: types,
    capacity: capacities,
    stick_type: stickTypes, // FIXME: usar camelCase en vez de snake_case
    clockFrequencyUnit: clockFrequencyUnits,
};
