import { Grid, Typography } from '@material-ui/core';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { ProductsFiltersContext } from '../../../contexts/ProductsFilters';
import { ProductType } from '../../../types/ProductType';
import { productTypeFilterSections } from '../data/productTypeFilterSections';
import { useProductFiltersStyles } from '../styles';
import FiltersAsChips from './FiltersAsChips';
import ProductTypesFilterButtons from './ProductTypesFilterButtons';

interface Props {
    onSelected?: () => void;
}

const ProductTypeSelection = ({ onSelected }: Props) => {
    const navigate = useNavigate();
    const classes = useProductFiltersStyles();
    const [{ offersOnly }, { setProductType }] = useContext(ProductsFiltersContext);

    const handleProductSelected = (productType: ProductType) => {
        setProductType(productType);
        onSelected?.();
    };

    return (
        <>
            <FiltersAsChips />
            <Grid container spacing={2}>
                {productTypeFilterSections.map(({ items, title }) => (
                    <Grid key={title} item xs={12} sm={4} md={12} lg={12}>
                        <Typography variant="subtitle1" className={classes.filterCategoryTitle}>
                            {title}
                        </Typography>
                        <ProductTypesFilterButtons items={items} onProductTypeSelected={handleProductSelected} />
                    </Grid>
                ))}
                {!offersOnly && (
                    <Grid item xs={12} sm={4} md={12} lg={12}>
                        <Typography
                            variant="subtitle1"
                            className={classes.filterCategoryTitleAndLink}
                            onClick={() => {
                                navigate('/ofertas');
                                onSelected?.();
                            }}
                        >
                            Ofertas
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default ProductTypeSelection;
