export default {
    yellow: '#F8E837',
    black: '#1C1C1C',
    white: '#F8F9FB',
    grey: '#F1F1F1',
    blackGrey: '#727272',
    green: '#4BBF10',
    lightGrey: '#DCDCDC',
    softGrey: '#f1f1f129',
};
