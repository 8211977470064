import { Box, Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useCheckout } from '../../../../../contexts/Checkout/useCheckout';
import paymentHelper from '../../../../../helpers/paymentHelper';
import GDTheme from '../../../../../theme/GDTheme';
import colors from '../../../../../theme/colors';
import CuotaSimpleLogo from '../../../../components/CuotaSimpleLogo';
import BankTransferImage from './bank-transfers_icon.svg';
import DebitCardOr1InstallmentImage from './debit-card-or-1-installment_icon.svg';

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        display: 'flex',
        gap: '8px',
        justifyContent: 'space-between',
        padding: '8px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row',
            gap: 0,
            padding: 0,
        },
    },
    paymentModeWrapper: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '48%',
        },
    },
    paymentModeSelector: {
        alignItems: 'center',
        backgroundColor: colors.grey,
        border: '2px solid transparent',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        height: '67px',
        justifyContent: 'space-between',
        padding: '18px 28px',
        width: '100%',
        '&:hover': {
            border: '2px solid',
            borderColor: colors.yellow,
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            height: '134px',
            justifyContent: 'center',
            marginBottom: 0,
            padding: '0px 18px',
        },
    },
    paymentModeCenterRowContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
    },
    paymentModeTextContainer: {
        width: '100%',
        justifyContent: 'space-between',
        gap: '8px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    highlightedSelector: {
        backgroundColor: colors.yellow,
    },
    selectorImage: {
        padding: 0,
        [theme.breakpoints.down('sm')]: {
            width: '47px',
            height: 'auto',
        },
    },
    selectorTypography: {
        fontWeight: 700,
        textAlign: 'left',
        paddingLeft: '27px',
        '@media only screen and (min-width: 960px) and (max-width:1024px)': {
            paddingRight: '8px',
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '0px',
            textAlign: 'center',
            fontSize: '12px',
            lineHeight: '14px',
        },
    },
    cuotaSimpleContainer: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            width: '49px',
            height: 'auto',
        },
    },
    stepChangeButton: {
        width: 129,
        height: 34,
        margin: GDTheme().spacing(2),
        marginTop: 0,
    },
    actionButtons: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
    },
}));

interface SelectPaymentSubStepProps {
    goToPreviousStep: () => void;
    goToConfirmPaymentSubStep: () => void;
    loadingTotalPrice: boolean;
}

const SelectPaymentSubStep = ({ goToPreviousStep, goToConfirmPaymentSubStep, loadingTotalPrice }: SelectPaymentSubStepProps) => {
    const classes = useStyles();
    const { selectedPaymentMode, setSelectedPaymentMode } = useCheckout();

    return (
        <>
            <Grid container className={classes.gridContainer} spacing={2}>
                <Grid className={classes.paymentModeWrapper}>
                    <Box
                        className={`
                            ${classes.paymentModeSelector}
                            ${selectedPaymentMode === paymentHelper.paymentModeConstants.CARD ? classes.highlightedSelector : ''}
                        `}
                        onClick={() => {
                            if (!loadingTotalPrice) setSelectedPaymentMode(paymentHelper.paymentModeConstants.CARD);
                        }}
                    >
                        <Box className={classes.paymentModeCenterRowContainer}>
                            <img src={DebitCardOr1InstallmentImage} alt="Tarjetas" className={classes.selectorImage} />
                        </Box>
                        <Box className={`${classes.paymentModeCenterRowContainer} ${classes.paymentModeTextContainer}`}>
                            <Typography variant="subtitle1" className={classes.selectorTypography}>
                                Pago con tarjeta
                            </Typography>
                            <Box className={classes.paymentModeCenterRowContainer}>
                                <Box className={classes.cuotaSimpleContainer}>
                                    <CuotaSimpleLogo />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid className={classes.paymentModeWrapper}>
                    <Box
                        className={`
                            ${classes.paymentModeSelector}
                            ${selectedPaymentMode === paymentHelper.paymentModeConstants.BANK_TRANSFER ? classes.highlightedSelector : ''}
                        `}
                        onClick={() => {
                            if (!loadingTotalPrice) setSelectedPaymentMode(paymentHelper.paymentModeConstants.BANK_TRANSFER);
                        }}
                    >
                        <Box className={classes.paymentModeCenterRowContainer}>
                            <img src={BankTransferImage} alt="Transferencias" className={classes.selectorImage} />
                        </Box>
                        <Box className={`${classes.paymentModeCenterRowContainer} ${classes.paymentModeTextContainer}`}>
                            <Typography variant="subtitle1" className={classes.selectorTypography}>
                                Pago con transferencia
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <Box className={classes.actionButtons} flexGrow={1} m={2}>
                <Button
                    variant="outlined"
                    onClick={() => {
                        setSelectedPaymentMode(undefined);
                        goToPreviousStep();
                    }}
                    className={classes.stepChangeButton}
                    disabled={loadingTotalPrice}
                >
                    Anterior
                </Button>
                <Button variant="contained" onClick={goToConfirmPaymentSubStep} className={classes.stepChangeButton} disabled={loadingTotalPrice || selectedPaymentMode === undefined}>
                    Siguiente
                </Button>
            </Box>
        </>
    );
};

export default SelectPaymentSubStep;
