/* eslint-disable react/jsx-no-duplicate-props */
import { Box, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, LinearProgress, NativeSelect, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AssignmentInd, CreditCard, VpnKey } from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/Info';
import Popover from 'material-ui-popup-state/HoverPopover';
import { bindHover, bindPopover, usePopupState } from 'material-ui-popup-state/hooks';
import Payment from 'payment';
import { useContext, useEffect, useState } from 'react';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { ValidatorForm } from 'react-material-ui-form-validator';

import { LABEL_IS_SUBSIDIZED_MP } from '../../../../../common/constants';
import { useCheckout } from '../../../../../contexts/Checkout/useCheckout';
import { UserContext } from '../../../../../contexts/User';
import cartHelper from '../../../../../helpers/cartHelper';
import useSnackbarGD from '../../../../../hooks/useSnackbar';
import colors from '../../../../../theme/colors';
import LoadingButton from '../../../../LoadingButton';
import { useMercadopago } from './useMercadopago';

const tarjetaNaranjaPattern = 589562;

Payment.addToCardArray({
    cvcLength: [3],
    format: /(\d{1,4})/g,
    length: [16],
    luhn: false,
    pattern: [tarjetaNaranjaPattern],
    type: 'naranja',
});

const cardWrapperStyle = (cardNumber) => {
    let styles = { transform: 'scale(0.75)' };
    if (String(cardNumber).startsWith(tarjetaNaranjaPattern)) {
        styles = {
            ...styles,
            '& .rccs__card.rccs__card--naranja .rccs__card__background': {
                background: 'linear-gradient(25deg, #e54400, #d83c01) !important',
            },
        };
    }
    return styles;
};

const useStyles = makeStyles((theme) => ({
    leftSideOfForm: {
        [theme.breakpoints.up('md')]: {
            marginTop: -20,
        },
    },
    cardContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        height: 'auto',
    },
    showOnlyOnMobile: {
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
    showOnlyOnDesktop: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    styleBottomHalf: {
        marginTop: '-12px',
        [theme.breakpoints.down('md')]: {
            marginTop: '4px',
        },
    },
    formItem: {
        [theme.breakpoints.up('lg')]: {
            paddingBottom: 0,
        },
    },
    buttonContainer: {
        paddingTop: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    iconMoreInformation: {
        marginLeft: '6px',
        height: 14,
        width: 14,
    },
    infoPaymentContainer: {
        backgroundColor: colors.white,
        padding: '9px 29px 10px 10px',
        width: '200px',
    },
    titleInfoPayment: {
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '15px',
        color: colors.blackGrey,
    },
    identificationTypeInput: {
        margin: '4px 0px 16px',
        [theme.breakpoints.down('md')]: {
            margin: '20px 0px 16px',
        },
    },
    emailInput: {
        margin: '4px 0px 16px',
        [theme.breakpoints.down('md')]: {
            margin: '0px 0px 16px',
        },
    },
}));

const onlyNumbers = (value) => value.replace(/\D/g, '');

const CardsPaymentSubStep = ({ generateOrder, goToSelectPaymentSubStep }) => {
    const classes = useStyles();
    const { loading: mpLoading, setLoading: setMpLoading, mercadopago } = useMercadopago();
    const [state] = useContext(UserContext);
    const {
        totalPriceToShow,
        installmentSelected,
        allowedInstallmentOptions,
        selectedPaymentMode,
        handleInstallmentsChange,
        onInstallmentsAllowedByMPChange,
        handleResetInstallmentSelected,
        setInstallmentSelected,
        getTotalPriceWithGFInterestFor,
    } = useCheckout();
    const { showSnackbarMessage } = useSnackbarGD();
    const popupState = usePopupState({
        variant: 'popover',
        popupId: 'cart-Popover',
    });

    const [loading, setLoading] = useState(false);
    const [formMounted, setFormMounted] = useState(false);
    const [isValidCardNumber, setIsValidCardNumber] = useState(false);
    const [cardNumberHasError, setCardNumberHasError] = useState(false);
    const [unavailableInstallmentsForCardNumber, setUnavailableInstallmentsForCardNumber] = useState(false);
    const [maxCreditCardNumberLength, setMaxCreditCardNumberLength] = useState(16);
    const [securityCode, setSecurityCode] = useState('');
    const [cardExpiration, setCardExpiration] = useState('');
    const [cardExpirationMonth, setCardExpirationMonth] = useState('');
    const [cardExpirationYear, setCardExpirationYear] = useState('');
    const [cardholderEmail, setCardholderEmail] = useState('');
    const [identificationNumber, setIdentificationNumber] = useState('');
    const [focused, setFocused] = useState('');
    const [cardholderName, setCardholderName] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [coupon, setCoupon] = useState(state?.user?.cart?.appliedCoupon?.code || null);
    const [cardForm, setCardForm] = useState(null);

    useEffect(() => {
        setCardExpiration(`${cardExpirationMonth.length === 1 ? '0' : ''}${cardExpirationMonth}/${cardExpirationYear}`);
    }, [cardExpirationMonth, cardExpirationYear]);

    useEffect(() => {
        setCardholderEmail(state.user.email);
    }, [state.user.email]);

    useEffect(() => {
        ValidatorForm.addValidationRule('isCreditCardValid', () => isValidCardNumber);
        return () => {
            ValidatorForm.removeValidationRule('isCreditCardValid');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const validation = focused === 'number' && cardNumber.length >= maxCreditCardNumberLength && !isValidCardNumber;
        const validation2 = focused !== 'number' && cardNumber.length > 0 && !isValidCardNumber;
        setCardNumberHasError(validation || validation2);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focused, cardNumber, isValidCardNumber, maxCreditCardNumberLength]);

    const handleChangeCardNumber = (e) => {
        const { value } = e.target;
        const valueOnlyNumbers = value.replace(/\D/g, '');
        setCardNumber(onlyNumbers(valueOnlyNumbers));
    };
    const handleChangeCardholderName = (e) => {
        setCardholderName(e.target.value.replace(/[^a-zA-Z ]/g, '').toUpperCase());
    };
    const handleChangeIdentificationNumber = (e) => {
        if (e.target.value.length <= 9) setIdentificationNumber(onlyNumbers(e.target.value));
    };
    const handleChangeCardholderEmail = (e) => {
        setCardholderEmail(e.target.value);
    };
    const handleChangeSecurityCode = (e) => {
        setSecurityCode(e.target.value);
    };
    const handleChangeExpirityMonth = (e) => {
        if (e.target.value.length <= 2) setCardExpirationMonth(onlyNumbers(e.target.value));
    };
    const handleChangeExpirityYear = (e) => {
        if (e.target.value.length <= 4) setCardExpirationYear(onlyNumbers(e.target.value));
    };

    const handleBlur = () => {
        setFocused('');
    };

    const shouldDisplayExpirityError = () => {
        if (cardExpiration.length < 5) return false;
        const month = cardExpirationMonth;
        if (Number.isNaN(month) || month < 1 || month > 12) return true;
        const year = cardExpirationYear.length === 2 ? `20${cardExpirationYear}` : cardExpirationYear;
        if (Number.isNaN(year)) return true;
        const now = new Date();
        return now > new Date(parseInt(year, 10), parseInt(month, 10), 0);
    };

    const expirityIsValid = cardExpiration.length >= 5 && !shouldDisplayExpirityError();

    const emailHasError = () => {
        const isValidEmail = (email) => email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        return cardholderEmail.length > 0 && !isValidEmail(cardholderEmail);
    };

    const isReadyToPay =
        identificationNumber &&
        securityCode &&
        cardholderName &&
        cardNumber &&
        isValidCardNumber &&
        cardholderEmail &&
        !emailHasError() &&
        expirityIsValid &&
        installmentSelected !== undefined &&
        selectedPaymentMode !== undefined;

    useEffect(() => {
        if (state?.user?.cart?.appliedCoupon?.code && coupon !== state?.user?.cart?.appliedCoupon?.code && cardForm) {
            setCoupon(state.user.cart.appliedCoupon.code);
            cardForm.unmount();
        }
    }, [coupon, state?.user?.cart?.appliedCoupon, cardForm]);

    const addInstallmentOptionToSelect = ({ totalPrice, installments, isSubsidized }) => {
        const installmentsSelect = document.getElementById('form-checkout__installments');
        if (!installmentsSelect) return;
        const opt = document.createElement('option');

        const installmentValue = totalPrice / installments;

        opt.text = `${String(installments)} cuotas de ${cartHelper.formatPrice(installmentValue)} - Total ${cartHelper.formatPrice(totalPrice)} ${isSubsidized ? ' [CUOTA SIMPLE]' : ''}`;
        opt.value = String(installments);
        installmentsSelect.appendChild(opt);
    };

    const loadInstallmentOptionsInForm = () => {
        handleResetInstallmentSelected();
        const installmentsSelect = document.getElementById('form-checkout__installments');
        if (!installmentsSelect) return;
        installmentsSelect.options.length = 0;

        if (!allowedInstallmentOptions.length) {
            setUnavailableInstallmentsForCardNumber(true);
        } else {
            setUnavailableInstallmentsForCardNumber(false);
            allowedInstallmentOptions.forEach(addInstallmentOptionToSelect);
            setInstallmentSelected(allowedInstallmentOptions[0]);
        }
    };

    useEffect(() => {
        loadInstallmentOptionsInForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allowedInstallmentOptions]);

    useEffect(() => {
        if (!mpLoading && mercadopago && !formMounted && !cardForm && getTotalPriceWithGFInterestFor(1) > 0) {
            setCardForm(
                mercadopago.cardForm({
                    amount: getTotalPriceWithGFInterestFor(1).toString(), //We generate the form with the price base price, then we add the GF interests once we have the MP installment options
                    autoMount: true,
                    form: {
                        id: 'form-checkout',
                        cardholderName: { id: 'form-checkout__cardholderName' },
                        cardholderEmail: { id: 'form-checkout__cardholderEmail' },
                        cardNumber: { id: 'form-checkout__cardNumber' },
                        cardExpirationMonth: { id: 'form-checkout__cardExpirationMonth' },
                        cardExpirationYear: { id: 'form-checkout__cardExpirationYear' },
                        securityCode: { id: 'form-checkout__securityCode' },
                        installments: { id: 'form-checkout__installments' },
                        identificationType: { id: 'form-checkout__identificationType' },
                        identificationNumber: { id: 'form-checkout__identificationNumber' },
                        issuer: { id: 'form-checkout__issuer' },
                    },
                    callbacks: {
                        onFormUnmounted: (error) => {
                            if (error) {
                                console.error('Form Mounted handling error: ', error);
                                showSnackbarMessage(
                                    'Hubo un problema generando el formulario de pago. Por favor intentá nuevamente en unos minutos. Si el problema persiste, contacte a su entidad bancaria o pruebe con otra tarjeta.'
                                );
                            } else {
                                setFormMounted(false);
                                setCardForm(null);
                            }
                        },
                        onFormMounted: (error) => {
                            if (error) {
                                console.error('Form Mounted handling error: ', error);
                                showSnackbarMessage(
                                    'Hubo un problema generando el formulario de pago. Por favor intentá nuevamente en unos minutos. Si el problema persiste, contacte a su entidad bancaria o pruebe con otra tarjeta.'
                                );
                            } else {
                                setFormMounted(true);
                                setMpLoading(false);
                            }
                        },
                        // eslint-disable-next-line no-unused-vars
                        onInstallmentsReceived: async (error, installmentsAllowedByMP) => {
                            if (error) {
                                showSnackbarMessage('Hubo un problema generando el formulario de pago. Por favor intentá nuevamente en unos minutos.');
                                onInstallmentsAllowedByMPChange([]);
                            } else {
                                onInstallmentsAllowedByMPChange(
                                    installmentsAllowedByMP.payer_costs.map(({ installments, installment_rate, labels }) => ({
                                        installments: Number(installments),
                                        installment_rate: Number(installment_rate),
                                        isSubsidized: labels.includes(LABEL_IS_SUBSIDIZED_MP),
                                    }))
                                );
                            }
                        },
                    },
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mercadopago, formMounted, mpLoading, cardForm, setMpLoading, getTotalPriceWithGFInterestFor(1)]);

    const [isFormDisabled, setIsFormDisabled] = useState(false);

    const handleComeBackButtonClick = () => {
        handleResetInstallmentSelected();
        goToSelectPaymentSubStep();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (cardNumberHasError || shouldDisplayExpirityError() || emailHasError()) return;
        setLoading(true);
        setIsFormDisabled(true);
        const amount = installmentSelected.totalPriceWithGFInterest;

        const tokenForMPCardFormData = await cardForm.createCardToken();
        const mpCardFormData = cardForm.getCardFormData();
        mpCardFormData.token = tokenForMPCardFormData.token;
        mpCardFormData.amount = amount;

        const paymentData = {
            amount,
            method: selectedPaymentMode,
            mpCardFormData,
            userInformedTotalPrice: totalPriceToShow,
        };
        generateOrder(paymentData).finally(() => {
            setLoading(false);
            setIsFormDisabled(false);
        });
    };

    const getCardNumberError = () => {
        if (cardNumberHasError) return 'El número de tarjeta es inválido';
        if (unavailableInstallmentsForCardNumber) {
            return 'No se encontraron opciones de pago disponibles para esta tarjeta';
        }
        return '';
    };

    return (
        <>
            {mpLoading ? (
                <Box mt={3}>
                    <LinearProgress color="primary" />
                    <Typography>Preparando el formulario de pago...</Typography>
                </Box>
            ) : (
                <>
                    <form id="form-checkout" autoComplete="off">
                        <Grid container spacing={4}>
                            <Grid item xs={12} lg={4} className={classes.leftSideOfForm}>
                                <Box className={classes.cardContainer}>
                                    <Box sx={cardWrapperStyle(cardNumber)}>
                                        <Cards
                                            cvc={securityCode}
                                            expiry={cardExpiration}
                                            focused={focused}
                                            name={cardholderName}
                                            number={cardNumber}
                                            locale={{ valid: 'fecha exp.' }}
                                            placeholders={{ name: 'NOMBRE Y APELLIDO' }}
                                            callback={({ maxLength }, isValid) => {
                                                setMaxCreditCardNumberLength(maxLength);
                                                setIsValidCardNumber(isValid);
                                            }}
                                            disabled={isFormDisabled}
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} lg={8}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} lg={7}>
                                        <TextField
                                            type="text"
                                            value={cardNumber}
                                            onChange={(e) => handleChangeCardNumber(e)}
                                            onFocus={() => setFocused('number')}
                                            onBlur={handleBlur}
                                            label="Número de la tarjeta"
                                            fullWidth
                                            id="form-checkout__cardNumber"
                                            error={cardNumberHasError || unavailableInstallmentsForCardNumber}
                                            helperText={getCardNumberError()}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <CreditCard />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            inputProps={{ maxLength: maxCreditCardNumberLength }}
                                            required
                                            className={classes.formItem}
                                            disabled={isFormDisabled}
                                        />
                                    </Grid>
                                    <Grid item xs={6} lg={5} className={classes.showOnlyOnMobile}>
                                        <TextField
                                            type="text"
                                            value={securityCode}
                                            onChange={handleChangeSecurityCode}
                                            onFocus={() => setFocused('cvc')}
                                            label="Código de seguridad"
                                            fullWidth
                                            onBlur={handleBlur}
                                            id="form-checkout__securityCode"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <VpnKey />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            required
                                            disabled={isFormDisabled}
                                        />
                                    </Grid>
                                    <Grid item xs={6} lg={5}>
                                        <Box display="flex" alignItems="flex-end">
                                            <TextField
                                                type="text"
                                                value={cardExpirationMonth}
                                                onFocus={() => setFocused('expiry')}
                                                onBlur={handleBlur}
                                                id="form-checkout__cardExpirationMonth"
                                                onChange={handleChangeExpirityMonth}
                                                label="Mes"
                                                placeholder="MM"
                                                error={shouldDisplayExpirityError()}
                                                inputProps={{ maxLength: 2, style: { textAlign: 'center' } }}
                                                required
                                                disabled={isFormDisabled}
                                            />
                                            <Typography variant="h3" style={{ marginRight: 10, marginLeft: 10 }}>
                                                /
                                            </Typography>
                                            <TextField
                                                type="text"
                                                value={cardExpirationYear}
                                                onFocus={() => setFocused('expiry')}
                                                onBlur={handleBlur}
                                                label="Año"
                                                placeholder="AA"
                                                id="form-checkout__cardExpirationYear"
                                                onChange={handleChangeExpirityYear}
                                                error={shouldDisplayExpirityError()}
                                                inputProps={{ maxLength: 4, style: { textAlign: 'center' } }}
                                                required
                                                disabled={isFormDisabled}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} lg={7}>
                                        <TextField
                                            type="text"
                                            fullWidth
                                            value={cardholderName}
                                            onFocus={() => setFocused('name')}
                                            label="Titular de la tarjeta"
                                            onBlur={handleBlur}
                                            id="form-checkout__cardholderName"
                                            onChange={handleChangeCardholderName}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <AssignmentInd />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            required
                                            inputProps={{ minLength: 3 }}
                                            className={classes.formItem}
                                            disabled={isFormDisabled}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={5} className={classes.showOnlyOnDesktop}>
                                        <TextField
                                            type="text"
                                            value={securityCode}
                                            onChange={handleChangeSecurityCode}
                                            onFocus={() => setFocused('cvc')}
                                            label="Código de seguridad"
                                            fullWidth
                                            onBlur={handleBlur}
                                            id="form-checkout__securityCode"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <VpnKey />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            required
                                            disabled={isFormDisabled}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={4} className={classes.styleBottomHalf}>
                                <FormControl fullWidth className={classes.identificationTypeInput}>
                                    <InputLabel variant="standard" htmlFor="uncontrolled-native" shrink>
                                        Tipo de documento
                                    </InputLabel>
                                    <NativeSelect
                                        inputProps={{
                                            id: 'form-checkout__identificationType',
                                            defaultValue: 'DNI',
                                        }}
                                        disabled={isFormDisabled}
                                    />
                                </FormControl>
                                <TextField
                                    value={identificationNumber}
                                    onChange={handleChangeIdentificationNumber}
                                    type="text"
                                    fullWidth
                                    label="Numero de documento"
                                    id="form-checkout__identificationNumber"
                                    required
                                    disabled={isFormDisabled}
                                    InputLabelProps={{ shrink: true }}
                                    style={{ margin: '16px 0px 4px' }}
                                />
                            </Grid>
                            <Grid item xs={12} lg={8} className={classes.styleBottomHalf}>
                                <TextField
                                    value={cardholderEmail}
                                    onChange={handleChangeCardholderEmail}
                                    error={emailHasError()}
                                    type="email"
                                    fullWidth
                                    label="Email"
                                    id="form-checkout__cardholderEmail"
                                    disabled={isFormDisabled}
                                    className={classes.emailInput}
                                />
                                <FormControl fullWidth style={{ margin: '16px 0px 4px' }}>
                                    <InputLabel variant="standard" htmlFor="uncontrolled-native" shrink>
                                        Cantidad de cuotas *
                                        <IconButton aria-label="moreInformation" color="inherit" style={{ color: colors.blackGrey }} {...bindHover(popupState)}>
                                            <InfoIcon className={classes.iconMoreInformation} style={{ padding: 0 }} />
                                        </IconButton>
                                        <Popover
                                            {...bindPopover(popupState)}
                                            anchorOrigin={{
                                                vertical: 'center',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'center',
                                                horizontal: 'left',
                                            }}
                                            disableRestoreFocus
                                        >
                                            <Box className={classes.infoPaymentContainer}>
                                                <Typography variant="subtitle2" className={classes.titleInfoPayment}>
                                                    <strong>El precio especial</strong> aplica a pagos con tarjeta de débito o crédito en una cuota.
                                                </Typography>
                                            </Box>
                                        </Popover>
                                    </InputLabel>
                                    <NativeSelect
                                        inputProps={{
                                            id: 'form-checkout__installments',
                                        }}
                                        disabled={isFormDisabled}
                                        onChange={handleInstallmentsChange}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={12}>
                                <Box className={classes.buttonContainer}>
                                    <Button variant="outlined" onClick={handleComeBackButtonClick}>
                                        Volver
                                    </Button>
                                    <Box ml={2}>
                                        <LoadingButton onClick={(e) => handleSubmit(e)} loading={loading} variant="contained" disabled={!isReadyToPay}>
                                            Pagar
                                        </LoadingButton>
                                    </Box>
                                </Box>
                            </Grid>
                            <select style={{ display: 'none' }} name="issuer" id="form-checkout__issuer" />
                        </Grid>
                    </form>
                </>
            )}
        </>
    );
};

export default CardsPaymentSubStep;
