const paymentModeConstants = {
    CARD: 'card',
    BANK_TRANSFER: 'bank_transfer',
} as const;

const paymentHelper = {
    paymentModeConstants,
};

export default paymentHelper;
