import { Box, Button, Container, Divider, Grid, Hidden, LinearProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';

import API from '../../api/API';
import { useConfig } from '../../contexts/Config/context';
import { configKeys } from '../../contexts/Config/types';
import { UserContext } from '../../contexts/User';
import { helmetHomeConfig } from '../../data/seo';
import { sendPageViewGAEvent } from '../../helpers/gaHelper';
import handler from '../../helpers/handler';
import { peripheralTypesDefinitions } from '../../helpers/types';
import useSnackbarGD from '../../hooks/useSnackbar';
import colors from '../../theme/colors';
import { withWhiteLineBackground } from '../../theme/styleUtils';
import RecoverPasswordModal from '../authentication/RecoverPasswordModal';
import HomePeripheralsButton from './HomePeripheralsButton';
import ImagesCarousel from './ImagesCarousel';
import ProductsCarousel from './ProductsCarousel';
import { BrandImage, Slide } from './types';

const useStyles = makeStyles(() => ({
    serviceIcon: {
        verticalAlign: 'bottom',
        marginRight: 7,
        '@media (min-width: 769px)': {
            marginRight: 18,
        },
    },
    buildersSection: {
        flexDirection: 'column',
        minHeight: 450,
        '@media (min-width: 769px)': {
            flexDirection: 'row',
        },
    },
    proBuilderSection: {
        flexDirection: 'column-reverse',
        minHeight: 450,
        '@media (min-width: 769px)': {
            flexDirection: 'row',
        },
    },
    easyBuilderContainer: {
        background: colors.yellow,
        minHeight: 539,
        marginBottom: '0 !important',
        '@media (min-width: 768px)': {
            minHeight: 700,
            margin: '20px !important',
        },
        '@media (min-width: 800px)': {
            minHeight: 0,
        },
    },
    easyBuilder: {
        '@media (min-width: 769px)': {
            '& div': {
                margin: 'auto',
            },
            ...withWhiteLineBackground(colors.yellow, true),
        },
        '@media (max-width: 768px)': {
            paddingTop: 20,
            paddingBottom: 20,
        },
    },
    proBuilderContainer: {
        background: colors.black,
        marginBottom: '0 !important',
        '@media (min-width: 768px)': {
            minHeight: 700,
            margin: '20px !important',
        },
        '@media (min-width: 800px)': {
            minHeight: 0,
        },
    },
    proBuilder: {
        color: colors.yellow,
        '@media (min-width: 769px)': {
            '& div': {
                margin: 'auto',
            },
            ...withWhiteLineBackground(colors.black, false),
        },
        '@media (max-width: 768px)': {
            paddingTop: 20,
            paddingBottom: 20,
        },
    },
    productsCarouselText: {
        textAlign: 'center',
    },
    infoText: {
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '19.2px',
        '@media (min-width: 769px)': {
            fontSize: '14px',
        },
    },
    easyBuilderButton: {
        boxShadow: 'none',
        minWidth: 128,
        color: colors.yellow,
        backgroundColor: colors.black,
        border: `1px solid ${colors.black}`,
        padding: '8px 16px 5px 16px',
        '&:hover': {
            color: colors.black,
            backgroundColor: colors.yellow,
        },
        '&:disabled': {
            color: colors.yellow,
            backgroundColor: colors.grey,
        },
    },
    proBuilderText: {
        marginBottom: 20,
        '@media (min-width: 769px)': {
            paddingLeft: 50,
        },
    },
    easyBuilderText: {
        paddingRight: 50,
        marginBottom: 20,
    },
    embedcontainer: {
        position: 'relative',
        paddingBottom: '56.25%',
        height: '0',
        overflow: 'hidden',
        minHeight: 221,
        border: '8px solid white',
    },
    videoFrame: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
    },
    product: {
        position: 'relative',
        border: '1px solid #DBDBD5',
        height: 152,
        width: 152,
        '&:hover, &$focusVisible': {
            background: colors.yellow,
            zIndex: 1,
            '& h6': {
                fontWeight: '700',
            },
        },
    },
    verticalDivider: {
        alignSelf: 'stretch',
        height: 'auto',
        backgroundColor: colors.black,
    },
    infoTexts: {
        textAlign: 'center',
        '@media (min-width: 769px)': {
            display: 'flex',
            justifyContent: 'center',
        },
        marginBottom: '60px !important',
    },
    brandSection: {
        backgroundColor: colors.softGrey,
        marginBottom: '50px !important',
        marginLeft: '0 !important',
        marginRight: '0 !important',
        maxWidth: '100%',
    },
    buildsContainer: {
        backgroundColor: colors.softGrey,
    },
    brandImageItem: {
        display: 'flex',
    },
    brandImage: {
        objectFit: 'contain',
        maxWidth: '100%',
    },
    peripheralsTitle: {
        fontSize: '15px',
        lineHeight: '18px',
        '@media (min-width: 769px)': {
            fontSize: '24px',
            lineHeight: '28.8px',
        },
    },
}));

const Home = () => {
    const classes = useStyles();
    const {
        state: { [configKeys.MAX_INSTALLMENTS]: maxInstallments },
    } = useConfig();
    const [builds, setBuilds] = useState<Slide[]>([]);
    const [slides, setSlides] = useState<Slide[]>([]);

    const [brandImages, setBrandImages] = useState<BrandImage[]>([]);
    const [, dispatch] = useContext(UserContext);
    const [recoverPasswordModalOpen, setRecoverPasswordModalOpen] = useState(false);
    const { showSnackbarMessage } = useSnackbarGD();
    const navigate = useNavigate();

    const { token } = useParams();

    useEffect(() => {
        API.builds
            .easyBuilderFind({})
            .then((response: { data: { data: Slide[] } }) => {
                const buildsResult = response.data.data;
                setBuilds(buildsResult);
            })
            .catch((err: unknown) => {
                void handler.handleError({ error: err, userContextDispatch: dispatch, showSnackbarMessage, navigate });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        sendPageViewGAEvent();
    }, []);

    useEffect(() => {
        API.slides
            .getAll()
            .then((response: { data: { data: Slide[] } }) => {
                const slidesResult: Slide[] = response.data.data;
                setSlides(slidesResult);
            })
            .catch((err: unknown) => {
                void handler.handleError({ error: err, userContextDispatch: dispatch, showSnackbarMessage, navigate });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        API.brandImages
            .getAll()
            .then((response: { data: { data: typeof brandImages } }) => {
                const brandImagesResult = response.data.data;
                setBrandImages(brandImagesResult);
            })
            .catch((err: unknown) => {
                void handler.handleError({ error: err, userContextDispatch: dispatch, showSnackbarMessage, navigate });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        if (token) setRecoverPasswordModalOpen(true);
    }, [token]);

    return (
        <Box>
            <Helmet>
                <title>{helmetHomeConfig.title}</title>
                <meta name="description" content={helmetHomeConfig.description} />
                <meta property="og:image" content="/GD_blacklogo.png" />
                <meta property="og:title" content={helmetHomeConfig.title} />
                <meta property="og:description" content={helmetHomeConfig.description} />
            </Helmet>
            <ImagesCarousel slides={slides} />
            <Container style={{ maxWidth: 'fit-content', paddingTop: 40 }}>
                <Box pb={6} textAlign={{ xs: 'center', md: 'start' }}>
                    <Typography variant="h5" className={classes.peripheralsTitle}>
                        ¿Buscás complementos para tu PC?
                    </Typography>
                </Box>
                <Box pb={8} display="flex" justifyContent="center" flexWrap="wrap">
                    {peripheralTypesDefinitions.map(({ title, image, type, peripheralInitialSpecsFilter, peripheralTitle }) => (
                        <HomePeripheralsButton key={peripheralTitle ?? title} title={peripheralTitle ?? title} image={image} type={type} initialSpecsFilter={peripheralInitialSpecsFilter} />
                    ))}
                </Box>
            </Container>
            <Box className={classes.infoTexts}>
                <Box p={2} px={4} width="100%" justifyItems={{ xs: 'normal', md: 'end' }}>
                    <Typography variant="h6" className={classes.infoText}>
                        <LocalShippingIcon className={classes.serviceIcon} />
                        Entregas rápidas a todo el país.
                    </Typography>
                </Box>
                <Hidden xsDown>
                    <Divider orientation="vertical" className={classes.verticalDivider} />
                </Hidden>
                <Box p={2} px={4} width="100%" justifyItems={{ xs: 'normal', md: 'start' }}>
                    <Typography variant="h6" className={classes.infoText}>
                        <CreditCardIcon className={classes.serviceIcon} />
                        Hasta {maxInstallments.installments} cuotas con todas las tarjetas
                    </Typography>
                </Box>
            </Box>
            <Grid container spacing={4} justifyContent="center" className={classes.brandSection}>
                {brandImages.map((brandImage) => (
                    <Grid item xs={6} sm={4} md={2} xl={1} key={brandImage.name} className={classes.brandImageItem}>
                        <a href={brandImage.link} target="_blank" rel="noopener noreferrer">
                            <img src={brandImage.image.url} alt={brandImage.name} width="150" height="80" className={classes.brandImage} />
                        </a>
                    </Grid>
                ))}
            </Grid>
            <Container maxWidth="xl" className={classes.buildsContainer}>
                <Box mt={2} className={classes.productsCarouselText}>
                    <Typography variant="h4">PCs completas</Typography>
                    <Typography style={{ fontWeight: 300 }} variant="h4">
                        ¡Listas para usar!
                    </Typography>
                </Box>
                <Box pb={8} display="flex" justifyContent="center">
                    {builds.length > 0 ? <ProductsCarousel builds={builds} /> : <LinearProgress color="primary" />}
                </Box>
            </Container>
            <Box className={classes.easyBuilderContainer}>
                <Container maxWidth="xl">
                    <Box className={classes.easyBuilder}>
                        <Grid container className={classes.buildersSection}>
                            <Grid item md={6}>
                                <Box className={classes.easyBuilderText}>
                                    <Box pb={4}>
                                        <Typography variant="h6">Armá tu PC</Typography>
                                        <Typography variant="h2" style={{ fontSize: 48, fontWeight: 400, lineHeight: '50px' }}>
                                            <span style={{ fontWeight: 850 }}>Easy</span> Builder
                                        </Typography>
                                    </Box>
                                    <Box pb={4}>
                                        <Typography variant="h6" style={{ fontWeight: 300 }}>
                                            <b>Ideal para jugadores que están empezando</b>
                                            <br />
                                            Armá tu PC a partir de una breve y fácil encuesta sobre tus juegos favoritos, presupuesto y dispositivo preferido.
                                        </Typography>
                                    </Box>
                                    <Button className={classes.easyBuilderButton} href="/easybuilder" variant="contained">
                                        Empezar
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item md={6}>
                                <Box className={classes.embedcontainer}>
                                    <iframe
                                        title="Setup Gaming"
                                        src="https://www.youtube.com/embed/LuVWjmxNn0I?rel=0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        className={classes.videoFrame}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
            <Box className={classes.proBuilderContainer}>
                <Container maxWidth="xl">
                    <Box className={classes.proBuilder}>
                        <Grid container className={classes.proBuilderSection}>
                            <Grid item md={6}>
                                <Box className={classes.embedcontainer}>
                                    <iframe
                                        title="My Epic Gaming"
                                        src="https://www.youtube.com/embed/RKZTKtqU9bg?rel=0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        className={classes.videoFrame}
                                    />
                                </Box>
                            </Grid>
                            <Grid item md={6}>
                                <Box className={classes.proBuilderText}>
                                    <Box pb={4}>
                                        <Typography variant="h6">Armá tu PC</Typography>
                                        <Typography variant="h2" style={{ fontSize: 48, fontWeight: 400, lineHeight: '50px' }}>
                                            <span style={{ fontWeight: 850 }}>Pro</span> Builder
                                        </Typography>
                                    </Box>
                                    <Box pb={4}>
                                        <Typography variant="h6" style={{ fontWeight: 300 }}>
                                            <b>Para jugadores con conocimientos en hardware.</b>
                                            <br />
                                            Elegí cada componente y armá la PC que mejor se adapte a tus preferencias.
                                        </Typography>
                                    </Box>
                                    <Button href="/probuilder" variant="contained">
                                        Empezar
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>

            <RecoverPasswordModal token={token} open={recoverPasswordModalOpen} setOpen={setRecoverPasswordModalOpen} />
        </Box>
    );
};

export default Home;
