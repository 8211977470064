import { Box, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';

import { useConfig } from '../../contexts/Config/context';
import { configKeys } from '../../contexts/Config/types';
import { helmetFAQConfig } from '../../data/seo';
import colors from '../../theme/colors';
import TextBlock from '../components/TextBlock';
import FaqDeleteAccount from './FaqDeleteAccount';
import FaqRegretPolicy from './FaqRegretPolicy';
import { paymentsTexts, purchasesTexts, shipmentsTexts, warrantyTexts } from './constants';

const useStyles = makeStyles(() => ({
    root: {
        minHeight: 'calc(100vh - 237px)',
        '@media (max-width: 959px)': {
            minHeight: 'calc(100vh - 420px)',
        },
        background: colors.black,
        color: colors.white,
    },
    container: {
        paddingTop: 24,
        paddingBottom: 24,
    },
}));

const FAQ = () => {
    const classes = useStyles();
    const {
        state: { [configKeys.MAX_INSTALLMENTS]: maxInstallments },
    } = useConfig();

    return (
        <>
            <Helmet>
                <title>{helmetFAQConfig.title}</title>
                <meta name="description" content={helmetFAQConfig.description} />
                <meta property="og:image" content="/GD_blacklogo.png" />
                <meta property="og:title" content={helmetFAQConfig.title} />
                <meta property="og:description" content={helmetFAQConfig.description} />
            </Helmet>
            <Box className={classes.root}>
                <Container maxWidth="xl" className={classes.container}>
                    <Typography
                        variant="h1"
                        align="center"
                        style={{
                            marginBottom: '12px',
                            color: colors.white,
                            fontSize: '20px',
                            lineHeight: '24px',
                        }}
                    >
                        Preguntas frecuentes
                    </Typography>

                    <TextBlock blockTitle="Compras" blockTexts={purchasesTexts} />
                    <TextBlock blockTitle="Pagos" blockTexts={paymentsTexts(maxInstallments.installments)} />
                    <TextBlock blockTitle="Envíos" blockTexts={shipmentsTexts} />
                    <TextBlock blockTitle="Garantía" blockTexts={warrantyTexts} />
                    <FaqRegretPolicy />
                    <br />
                    <FaqDeleteAccount />
                </Container>
            </Box>
        </>
    );
};
export default FAQ;
