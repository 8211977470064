import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';

import './App.css';
import GDFooter from './GDFooter';
import GDHeader from './GDHeader';
import GDRouter from './GDRouter';
import { SnackbarUtilsConfigurator } from './SnackBarUtils';
import CheckoutProvider from './contexts/Checkout/context';
import ConfigProvider from './contexts/Config/context';
import ProductsContextProvider from './contexts/Products/context';
import { ProductsFiltersProvider } from './contexts/ProductsFilters';
import { UserProvider } from './contexts/User';
import GDTheme from './theme/GDTheme';

const theme = GDTheme();

function App() {
    return (
        <BrowserRouter
            future={{
                v7_startTransition: true,
            }}
        >
            <SnackbarProvider>
                <SnackbarUtilsConfigurator />
                <ConfigProvider>
                    <UserProvider>
                        <CheckoutProvider>
                            <ProductsFiltersProvider>
                                <ProductsContextProvider>
                                    <ThemeProvider theme={theme}>
                                        <CssBaseline />
                                        <GDHeader />
                                        <GDRouter />
                                        <GDFooter />
                                    </ThemeProvider>
                                </ProductsContextProvider>
                            </ProductsFiltersProvider>
                        </CheckoutProvider>
                    </UserProvider>
                </ConfigProvider>
            </SnackbarProvider>
        </BrowserRouter>
    );
}

export default App;
