import axios, { AxiosError, AxiosResponse } from 'axios';

const API_URL = process.env.REACT_APP_API_URL ?? '';
const GIT_SHA = process.env.REACT_APP_GIT_SHA ?? '';

const GDAxios = axios.create({
    baseURL: API_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
});

interface CustomHeaders extends Record<string, string | undefined> {
    'x-frontend-version'?: string;
}

const validateVersion = (serverVersion?: string): void => {
    if (!serverVersion || serverVersion === GIT_SHA) return;

    if (!window.location.pathname.includes('/logout')) window.location.href = '/logout?new-version';
};

GDAxios.interceptors.response.use(
    (response: AxiosResponse) => {
        const headers = response.headers as CustomHeaders;
        validateVersion(headers['x-frontend-version']);
        return response;
    },
    (error: AxiosError) => Promise.reject(error)
);

export default GDAxios;
