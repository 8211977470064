import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import cartHelper from '../../helpers/cartHelper';
import cabalIcon from '../../icons/cabal.svg';
import mastercardIcon from '../../icons/mastercard.svg';
import visaIcon from '../../icons/visa.svg';
import CuotaSimpleLogo from '../components/CuotaSimpleLogo';
import { usePayerCostTableStyles } from './styles';
import { PayerCostTableProps } from './types';

const PayerCostTable = ({ payerCosts }: PayerCostTableProps) => {
    const classes = usePayerCostTableStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    return (
        <Accordion className={classes.accordion}>
            <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
                <Box display="flex" justifyContent="space-between" width="100%">
                    <Typography className={classes.heading}>Ver cuotas*</Typography>
                    <Box display="flex" gridGap={15}>
                        <img src={visaIcon} height={14} alt="visa" />
                        <img src={mastercardIcon} height={14} alt="mastercard" />
                        <img src={cabalIcon} height={14} alt="cabal" />
                    </Box>
                </Box>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
                <Grid container spacing={2} direction="column" className={classes.tableContainer}>
                    <TableContainer>
                        <Table className={classes.table}>
                            <TableHead className={classes.tableHead}>
                                <TableRow>
                                    <TableCell align="center" className={classes.tableHeader}>
                                        Cuotas
                                    </TableCell>
                                    <TableCell align="center" className={classes.tableHeader}>
                                        Valor de cuotas
                                    </TableCell>
                                    <TableCell align="center" className={`${classes.tableHeader} ${classes.alignRightOnMobile}`}>
                                        Precio final
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {payerCosts.length > 0 &&
                                    payerCosts.map((row) => (
                                        <TableRow key={row.installments}>
                                            <TableCell className={classes.tableCell}>
                                                <Box className={classes.tableBoxCell}>
                                                    {`${row.installments.toString()} Cuota${row.installments > 1 ? 's' : ''}`}
                                                    {row.isSubsidized && <CuotaSimpleLogo width={isDesktop ? 14 : 43} withoutLegend={isDesktop} />}
                                                </Box>
                                            </TableCell>
                                            <TableCell align="center" className={`${classes.tableCell} ${classes.showOnDesktop}`}>
                                                {cartHelper.formatPrice(row.installmentAmount)}
                                            </TableCell>
                                            <TableCell className={`${classes.tableCell} ${classes.alignRightOnMobile}`}>{cartHelper.formatPrice(row.totalAmount)}</TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Typography className={classes.disclaimerText}>
                        * Aceptamos todas las tarjetas de crédito y débito. Los montos finales pueden variar según la cantidad de cuotas y la tarjeta seleccionada. Algunas tarjetas pueden no
                        estar adheridas al programa Cuota Simple y aplicar recargo según el emisor. Los intereses, si corresponden, se verán detallados en el checkout antes de finalizar la
                        compra.
                    </Typography>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default PayerCostTable;
