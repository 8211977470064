import { Box, Button, Card, CardActions, CardContent, Typography } from '@material-ui/core';
import { useState } from 'react';

import cartHelper from '../../../helpers/cartHelper';
import ModalPreViewProduct from '../ModalPreViewProduct';
import ProductQuantitySelection from '../ProductQuantitySelection';
import { shouldShowStrikethroughPrice } from '../utils';
import ProductImageContainer from './ProductImageContainer';
import useStyles from './styles';

const getButtonText = (isSelected, selectedQuantity) => {
    if (isSelected) {
        return selectedQuantity > 1 ? `Agregado (${selectedQuantity})` : 'Agregado';
    }
    return 'Agregar';
};

const ProductCard = ({
    product,
    highlightWhenSelected,
    isSelected,
    multiSelect,
    handleChangeQuantity,
    selectedQuantity,
    handleAddProduct,
    handleRemoveProduct,
    imageMustOpenPreviewModal = false,
}) => {
    const classes = useStyles();
    const [modalPreViewProduct, setModalPreViewProduct] = useState(false);
    const showStrikethroughPrice = shouldShowStrikethroughPrice(product.price.special.strikethrough, product.price.special.ARS);

    const toggleSelection = () => {
        if (!isSelected) handleAddProduct(product);
        else handleRemoveProduct(product);
    };

    return (
        <>
            <Box className={`${classes.cardWrapper} ${highlightWhenSelected && isSelected && classes.checked}`}>
                <Card className={classes.card}>
                    {product.label && (
                        <Box mt={2} px={2} py={1} className={classes.label}>
                            {product.label}
                        </Box>
                    )}
                    <CardContent className={classes.cardContent}>
                        <ProductImageContainer product={product} imageMustOpenPreviewModal={imageMustOpenPreviewModal} setModalPreViewProduct={setModalPreViewProduct} />
                        <Box my={1} mx={2} className={classes.productTitleWrapper}>
                            <Typography className={classes.productTitle}>{product.name.toUpperCase()}</Typography>
                        </Box>
                        <Box my={1} display="flex" justifyContent="center">
                            {showStrikethroughPrice && (
                                <Typography variant="h6" className={classes.strikethroughPrice}>
                                    {cartHelper.formatPrice(product.price.special.strikethrough)}
                                </Typography>
                            )}
                            <Typography variant="h5">{cartHelper.formatPrice(product.price.special.ARS)}</Typography>
                        </Box>
                    </CardContent>
                    <CardActions className={classes.cardBottomActions}>
                        <Box display="flex" justifyContent="center" flexDirection="column">
                            <Box display="block" textAlign="center">
                                {multiSelect && <ProductQuantitySelection onQuantityChanged={handleChangeQuantity} selectedQuantity={selectedQuantity} maxQuantity={product.stock} />}

                                <Button className={isSelected ? classes.removeProduct : classes.addProduct} onClick={toggleSelection} variant="contained">
                                    {getButtonText(isSelected, selectedQuantity)}
                                </Button>
                            </Box>
                        </Box>
                    </CardActions>
                </Card>
            </Box>
            <ModalPreViewProduct
                handleAddProduct={handleAddProduct}
                handleChangeQuantity={handleChangeQuantity}
                isSelected={isSelected}
                multiSelect={multiSelect}
                open={modalPreViewProduct}
                product={product}
                selectedQuantity={selectedQuantity}
                setOpen={setModalPreViewProduct}
            />
        </>
    );
};

export default ProductCard;
