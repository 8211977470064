import { Box, makeStyles, useMediaQuery } from '@material-ui/core';
import { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const useStyles = makeStyles(() => ({
    arrowStyles: {
        position: 'absolute',
        zIndex: 2,
        top: 'calc(50% - 15px)',
        width: 30,
        height: 30,
        cursor: 'pointer',
        '@media (min-width: 1920px)': {
            height: 90,
            width: 90,
        },
    },
    leftArrowStyles: {
        left: 0,
    },
    rightArrowStyles: {
        right: 0,
    },
    indicator: {
        background: '#1C1C1C',
        width: 10,
        height: 10,
        border: '1px solid #F8F9FB',
        display: 'inline-block',
        margin: '0 8px',
        '@media (min-width: 1920px)': {
            height: 20,
            width: 20,
        },
    },
    indicatorSelected: {
        background: '#F8F9FB',
    },
    blackBackground: {
        background: '#1C1C1C',
        minHeight: '32vw',
    },
    slideLink: {
        display: 'block',
    },
    contentCarousel: {
        width: '100%',
        height: 'auto',
    },
}));

const ImagesCarousel = ({ slides }) => {
    const classes = useStyles();
    const showVerticalImages = useMediaQuery('(max-aspect-ratio: 5/8)');

    const [loadedImage, setLoadedImage] = useState(false);
    const handleImageOnLoad = () => {
        setLoadedImage(true);
    };
    const arrowPrev = (onClickHandler, hasPrev, label) => {
        const pointer = 'prev';
        const style = 'GDCarousel__arrows GDCarousel__arrowLeft GDCarousel_whiteArrows';
        if (!loadedImage) return null;
        return (
            <button type="button" onClick={onClickHandler} title={label} className={`${style} ${classes.arrowStyles} ${classes.leftArrowStyles}`}>
                <span>{pointer}</span>
            </button>
        );
    };
    const arrowNext = (onClickHandler, hasNext, label) => {
        const pointer = 'next';
        const style = 'GDCarousel__arrows GDCarousel__arrowRight GDCarousel_whiteArrows';
        if (!loadedImage) return null;
        return (
            <button type="button" onClick={onClickHandler} title={label} className={`${style} ${classes.arrowStyles} ${classes.rightArrowStyles}`}>
                <span>{pointer}</span>
            </button>
        );
    };

    const indicators = (onClickHandler, isSelected, index, label) => {
        if (isSelected) {
            return <li className={`${classes.indicator} ${classes.indicatorSelected}`} aria-label={`Selected: ${label} ${index + 1}`} title={`Selected: ${label} ${index + 1}`} />;
        }
        return (
            <li
                className={classes.indicator}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                role="button"
                tabIndex={0}
                title={`${label} ${index + 1}`}
                aria-label={`${label} ${index + 1}`}
            />
        );
    };
    const imageUrl = ({ image, verticalImage }) => (showVerticalImages ? verticalImage.url : image.url);
    return (
        <Box className={classes.blackBackground}>
            <Box className={classes.contentCarousel}>
                {slides.length > 0 && (
                    <Carousel
                        autoPlay
                        interval={2500}
                        infiniteLoop
                        renderArrowPrev={arrowPrev}
                        renderArrowNext={arrowNext}
                        showArrows={loadedImage}
                        showIndicators={loadedImage}
                        showStatus={false}
                        showThumbs={false}
                        renderIndicator={indicators}
                    >
                        {slides.map((slide) => (
                            <div key={slide.title}>
                                <a href={slide.link} className={classes.slideLink} target="_blank" rel="noopener noreferrer">
                                    <img alt={slide.title} src={imageUrl(slide)} onLoad={handleImageOnLoad} />
                                </a>
                            </div>
                        ))}
                    </Carousel>
                )}
            </Box>
        </Box>
    );
};

export default ImagesCarousel;
