import { Box, Divider, Typography } from '@material-ui/core';

import { useCheckout } from '../../../contexts/Checkout/useCheckout';
import cartHelper from '../../../helpers/cartHelper';
import { BlackSmallCircularProgress } from '../BlackSmallCircularProgress';
import AppliedCouponCode from './AppliedCouponCode';
import ShippingCost from './ShippingCost';
import Total from './Total';
import { useCouponStyles } from './useStyles';

interface Props {
    showShippingCosts: boolean;
    loadingTotalPrice?: boolean;
    loadingShippingPrice?: boolean;
}
const TotalWithCoupon = ({ showShippingCosts, loadingTotalPrice = false, loadingShippingPrice = false }: Props) => {
    const classes = useCouponStyles();
    const { productsPriceWithGFInterest } = useCheckout();

    return (
        <>
            <Box className={classes.totalSubItemContainer}>
                <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                    Subtotal
                </Typography>
                <Box display="flex" justifyContent="flex-end">
                    {loadingTotalPrice ? (
                        <BlackSmallCircularProgress />
                    ) : (
                        <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                            {cartHelper.formatPrice(productsPriceWithGFInterest)}
                        </Typography>
                    )}
                </Box>
            </Box>
            <Divider className={classes.couponDivider} />

            <AppliedCouponCode />
            <Divider className={classes.couponDivider} />
            <ShippingCost showShippingCosts={showShippingCosts} loadingShippingPrice={loadingShippingPrice} />
            <Divider className={classes.couponDivider} />
            <Total loadingTotalPrice={loadingTotalPrice} />
        </>
    );
};

export default TotalWithCoupon;
