import { Box, ButtonBase, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';

import { SpecValue } from '../../contexts/ProductsFilters/types';
import { helmetConfigForProductTypes } from '../../data/seo';
import colors from '../../theme/colors';
import { OptionalLteGte } from '../../types/OptionalLteGte';
import { ProductType } from '../../types/ProductType';
import { serializeSpecs } from '../products-page/utils';

const useStyles = makeStyles(() => ({
    product: {
        position: 'relative',
        border: '1px solid #DBDBD5',
        height: 100,
        width: 100,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: '100px 100px',
        '&:hover, &$focusVisible': {
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: '152px 152px',
            background: colors.yellow,
            zIndex: 1,
            '& h6': {
                fontWeight: '700',
            },
        },
        '@media (min-width:769px)': {
            backgroundSize: '152px 152px',
            height: 152,
            width: 152,
        },
    },
}));

interface Props {
    title: string;
    image: string;
    type: ProductType;
    initialSpecsFilter?: Record<string, Set<SpecValue> | OptionalLteGte>;
}

const HomePeripheralsButton = ({ title, image, type, initialSpecsFilter }: Props) => {
    const navigate = useNavigate();
    const classes = useStyles();
    const config = helmetConfigForProductTypes[type];
    const baseUrl = `/productos/${config.productTypeSlug}`;
    let queryString = '';
    if (initialSpecsFilter) {
        const serializedSpecs = serializeSpecs(initialSpecsFilter);
        queryString = '?' + new URLSearchParams({ specs: serializedSpecs }).toString();
    }
    const url = baseUrl + queryString;

    return (
        <ButtonBase
            focusRipple
            className={classes.product}
            onClick={() => {
                navigate(url);
            }}
            style={{
                backgroundImage: `url(${image})`,
            }}
        >
            <Box display="flex" justifyContent="center" flexDirection="column" mt={{ xs: 8.75, md: 15 }}>
                <Typography variant="subtitle1">{title}</Typography>
            </Box>
        </ButtonBase>
    );
};
export default HomePeripheralsButton;
