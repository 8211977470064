import { KeyValue } from '../../types/KeyValue';
import boolean from './boolean';

const panelTypes: KeyValue[] = [
    { key: 'ips', value: 'IPS' },
    { key: 'oled', value: 'OLED' },
    { key: 'tn', value: 'TN' },
    { key: 'va', value: 'VA' },
];

const refreshRates: KeyValue[] = [
    { key: '60hz', value: '60Hz' },
    { key: '75hz', value: '75Hz' },
    { key: '120hz', value: '120Hz' },
    { key: '144hz', value: '144Hz' },
    { key: '165hz', value: '165Hz' },
    { key: '180hz', value: '180Hz' },
    { key: '240hz', value: '240Hz' },
    { key: '300hz', value: '300Hz' },
    { key: '360hz', value: '360Hz' },
];

const resolutions: KeyValue[] = [
    { key: 'fullHd', value: 'FULL HD' },
    { key: '2k', value: '2K' },
    { key: '4k', value: '4K' },
];

const vesaOptions: KeyValue[] = [
    { key: '100x100', value: '100x100' },
    { key: '75x75', value: '75x75' },
];

export default {
    amdFreeSync: boolean,
    curvedScreen: boolean,
    nvidiaGSync: boolean,
    panelType: panelTypes,
    refresh_rate: refreshRates, // FIXME: cambiar de snake_case a camelCase
    resolution: resolutions,
    vesa: vesaOptions,
};
