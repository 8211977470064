import { Box, ButtonBase, Typography } from '@material-ui/core';

import { useProductTypeButtonStyles } from './styles';

interface Props {
    title: string;
    image: string;
    onClick: () => void;
}

const ProductTypeButton = ({ title, image, onClick }: Props) => {
    const classes = useProductTypeButtonStyles();

    return (
        <ButtonBase focusRipple className={classes.product} onClick={onClick}>
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" height="100%" marginTop={0}>
                <img src={image} alt={title} className={classes.img} />
                <Typography variant="subtitle1">{title}</Typography>
            </Box>
        </ButtonBase>
    );
};

export default ProductTypeButton;
