import { Dispatch, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useSearchParams } from 'react-router-dom';

import API from '../../api/API';
import { UserContext } from '../../contexts/User';
import { userInitialState } from '../../contexts/User/reducer';
import userConstants from '../../contexts/User/userConstants';
import storageHelper from '../../helpers/storageHelper';
import useSnackbarGD from '../../hooks/useSnackbar';

// TODO: When migrating the UserContext to TypeScript, update to a more specific type
interface UserAction {
    type: string;
    payload?: unknown;
}

const Logout = () => {
    const [, dispatch] = useContext(UserContext) as [typeof userInitialState, Dispatch<UserAction>];
    const navigate = useNavigate();
    const { showSnackbarMessage } = useSnackbarGD();

    const [searchParams] = useSearchParams();
    const didNewVersionCausedLogout = searchParams.get('new-version') !== null;

    const handleLogout = async () => {
        storageHelper.clearSessionStorage();
        await API.auth.logout();
        dispatch({ type: userConstants.LOGOUT });
        storageHelper.clearStorage();
    };

    useEffect(() => {
        void handleLogout();
        if (didNewVersionCausedLogout) showSnackbarMessage('Hay una nueva versión de Gamer Factory para vos. Es posible que tengas que volver a iniciar sesión.', 'warning');
        navigate('/');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex" />
                <meta property="og:image" content="/GD_blacklogo.png" />
            </Helmet>
        </>
    );
};

export default Logout;
